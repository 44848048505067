import React from 'react'
import { Box, Typography, styled } from '@material-ui/core'
import { observer } from 'mobx-react-lite'

const MaintenanceImage = styled('img')({
	maxWidth: '100%',
	height: 'auto',
	marginBottom: '40px',
})

const Title = styled(Typography)({
	color: '#1E3A8A',
	fontSize: '32px',
	fontWeight: 600,
	marginBottom: '24px',
	textAlign: 'center',
})

const Description = styled(Typography)({
	color: '#6B7280',
	fontSize: '16px',
	textAlign: 'center',
	maxWidth: '652px',
	marginBottom: '16px',
})

export const ErrorContent = observer(() => {
	return (
		<Box
			position="fixed"
			top={0}
			left={0}
			right={0}
			bottom={0}
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			bgcolor="#ffffff"
			zIndex={9999}
		>
			<MaintenanceImage src="/assets/maintenance.svg" alt="Configuration Error" width={400} />
			<Title variant="h1">Network Connection Error</Title>
			<Description>We're experiencing slow network connection while loading your organization's configuration.</Description>
			<Description>Please wait while we try to reconnect...</Description>
			<Description>If this issue persists, please check your network connection or contact your system administrator.</Description>
		</Box>
	)
})
