import { isEmpty } from 'lodash'
import { ACCEPT_TERM_OF_SERVICE_MUTATION, apolloClient, WORKER_ONBOARDING_QUERY } from '~/common/apollo'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { onboardingProgressStore } from '~/components/onboarding-progress/onboarding-progress.store'
import { notifyStore } from '~/stores'
import { careActivationStore } from '../../care-activation.store'
import { PROFILE_STEP } from '~/common/constants'
import { infoTabStore } from '../info/info-tab.store'

@store()
class TermsTabStore {
	@observable termData = {}
	@observable isOpen = false

	@computed
	get content() {
		return this.termData?.content
	}

	@computed
	get description() {
		return this.termData?.description
	}
	@computed
	get id() {
		return this.termData?.id
	}

	@computed
	get acceptTerms() {
		return !isEmpty(this.termData?.acceptTerms) ? this.termData?.acceptTerms : []
	}

	@computed
	get nearestIncompleteStep() {
		return onboardingProgressStore.sortedOnboardingSteps.find(
			(item) => item?.completedPercent !== 100 && !item?.stepInfo?.optional && item?.stepName !== PROFILE_STEP.term_sign
		)
	}

	@action
	reset = async () => {
		this.termData = {}
		this.isOpen = false
	}

	@action
	fetchTermsService = async () => {
		const response = await apolloClient().query({
			query: WORKER_ONBOARDING_QUERY,
		})
		this.termData = response?.data?.workerOnboarding?.termsOfService
	}

	@action
	submitTermSignedStep = async (onVerifyEmailFuc = null) => {
		try {
			if (!isEmpty(this.nearestIncompleteStep)) {
				return notifyStore.open('Please complete all mandatory steps of your profile before completing this screen.', {
					title: "You haven't completed your profile yet",
					showDialog: true,
					keepOriginal: true,
					okText: 'Complete now',
					cancelText: 'Close',
					onOk: (key) => {
						notifyStore.closeDialog(key)
						careActivationStore.changeActiveTab(this.nearestIncompleteStep?.id)
					},
					onCancel: (key) => {
						notifyStore.closeDialog(key)
					},
				})
			} else {
				if (!infoTabStore.emailVerified && !infoTabStore.phoneVerified) {
					onVerifyEmailFuc && onVerifyEmailFuc(true)
					return
				}
				this.isOpen = true
				await this.acceptTermsOfService(this.id)
				await onboardingProgressStore.fetchOnboardingOverview()
			}
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	acceptTermsOfService = async (id) => {
		const response = await apolloClient().mutate({
			mutation: ACCEPT_TERM_OF_SERVICE_MUTATION,
			variables: {
				id: id,
			},
		})
		return response.data?.acceptTermsOfService?.id
	}
}

export const termsTabStore = new TermsTabStore()
