import { isNil, isEmpty, flatMap, differenceBy } from 'lodash'

/**
 * Map specialty data to standard format
 * @param {Object} specialty - Specialty data
 * @param {string} disciplineId - Discipline ID
 * @returns {Object} - Formatted specialty data
 */
export const mapSpecialtyData = (specialty, disciplineId) => ({
	id: specialty.parentId,
	disciplineId: disciplineId,
	specialtyId: specialty.id,
	isPrimary: specialty.isPrimary || false,
})

/**
 * Check if primary specialty has changed
 * @param {Object} oldSpecialty - Old primary specialty
 * @param {Object} newSpecialty - New primary specialty
 * @returns {boolean} - Whether primary specialty has changed
 */
export const hasPrimarySpecialtyChanged = (oldSpecialty, newSpecialty) => {
	if (!oldSpecialty || !newSpecialty) return false

	const oldId = oldSpecialty?.hasOwnProperty('id') && !isEmpty(oldSpecialty) ? oldSpecialty?.id : oldSpecialty?.parentId
	const newId = newSpecialty?.hasOwnProperty('id') && !isEmpty(newSpecialty) ? newSpecialty?.id : newSpecialty?.parentId

	return oldId !== newId && !!oldId && !!newId
}

/**
 * Create primary specialty change objects
 * @param {Object} oldPrimary - Old primary specialty
 * @param {Object} newPrimary - New primary specialty
 * @returns {Array} - Array of change objects
 */
export const createPrimarySpecialtyChanges = (oldPrimary, newPrimary) => {
	const changes = []

	if (oldPrimary) {
		changes.push({
			id: oldPrimary?.parentId,
			disciplineId: oldPrimary?.key || oldPrimary?.discipline?.id,
			specialtyId: oldPrimary?.id || oldPrimary?.specialty?.id,
			isPrimary: false,
		})
	}

	if (newPrimary) {
		changes.push({
			id: newPrimary?.parentId,
			disciplineId: newPrimary?.key || newPrimary?.discipline?.id,
			specialtyId: newPrimary?.id || newPrimary?.specialty?.id,
			isPrimary: true,
		})
	}

	return changes
}

/**
 * Group specialties by discipline
 * @param {Array} specialties - Array of specialties
 * @returns {Object} - Grouped specialties
 */
export const groupSpecialtiesByDiscipline = (specialties = []) => {
	if (!Array.isArray(specialties)) return {}

	return specialties.reduce((acc, specialty) => {
		const disciplineId = specialty.discipline?.id
		if (!disciplineId) return acc

		if (!acc[disciplineId]) {
			acc[disciplineId] = []
		}

		acc[disciplineId].push({
			...specialty.specialty,
			parentId: specialty.id,
			isPrimary: specialty.isPrimary,
		})

		return acc
	}, {})
}

/**
 * Find primary specialty from specialties array
 * @param {Array} specialties - Array of specialties
 * @returns {Object} - Primary specialty info
 */
export const findPrimarySpecialty = (specialties = []) => {
	const primarySpecialty = specialties?.find((specialty) => specialty.isPrimary)

	if (!primarySpecialty) return {}

	return {
		profession: primarySpecialty?.discipline?.id,
		professionName: primarySpecialty?.discipline?.disciplineName,
		specialty: primarySpecialty?.specialty?.id,
		specialtyName: primarySpecialty?.specialty?.name,
		parentId: primarySpecialty?.id,
		hasSkillChecklist: primarySpecialty.specialty?.hasSkillChecklist,
	}
}

/**
 * Remove duplicates from specialty changes array, prioritizing items with id
 * @param {Array} specialtyChanges - Array of specialty changes
 * @returns {Array} - Array with duplicates removed
 */
export const removeDuplicateSpecialties = (specialtyChanges) => {
	if (!Array.isArray(specialtyChanges)) return []

	return specialtyChanges.reduce((acc, current) => {
		if (current._destroy) return [...acc, current]

		const existingItemIndex = acc.findIndex(
			(item) =>
				!item._destroy &&
				item.disciplineId === (current.disciplineId || current.discipline?.id) &&
				item.specialtyId === (current.specialtyId || current.specialty?.id)
		)

		if (existingItemIndex === -1) return [...acc, current]

		const currentId = current.id || current.parentId
		const existingId = acc[existingItemIndex].id || acc[existingItemIndex].parentId

		if (currentId && !existingId) {
			acc[existingItemIndex] = current
		}

		return acc
	}, [])
}

/**
 * Process and format specialty changes
 * @param {Array} specialtyChanges - Array of specialty changes
 * @returns {Array} - Processed and formatted specialty changes
 */
export const processSpecialtyChanges = (specialtyChanges) => {
	if (!Array.isArray(specialtyChanges)) return []

	return specialtyChanges
		.filter((item) => item.disciplineId || item.discipline?.id)
		.map((item) => ({
			id: item.id || item.parentId,
			disciplineId: item.disciplineId || item.discipline?.id,
			specialtyId: item.specialtyId || item.specialty?.id,
			isPrimary: item.isPrimary || false,
			_destroy: item._destroy || false,
		}))
}

/**
 * Handle specialty changes with duplicate removal and formatting
 * @param {Array} initialSpecialties - Initial specialties array
 * @param {Array} newItems - New items to add
 * @param {Array} removedItems - Items marked for removal
 * @returns {Object} - Object containing processed specialties and update data
 */
export const handleSpecialtyChanges = (initialSpecialties = [], newItems = [], removedItems = []) => {
	let allSpecialtyChanges = [...initialSpecialties, ...newItems, ...removedItems]

	// Remove duplicates
	allSpecialtyChanges = removeDuplicateSpecialties(allSpecialtyChanges)

	// Process and format specialties
	const finalSpecialties = processSpecialtyChanges(allSpecialtyChanges)

	return {
		specialties: finalSpecialties,
		updateData: finalSpecialties.length > 0 ? { workerDisciplineSpecialties: finalSpecialties } : {},
	}
}

/**
 * Process specialty changes for submission
 * @param {Object} oldProfessionsSpecialties - Current specialties in store
 * @param {Object} newProfessionsSpecialties - New specialties to be updated
 * @returns {Array} - Array of specialty changes ready for submission
 */
export const processSpecialtySubmission = (oldProfessionsSpecialties, newProfessionsSpecialties) => {
	if (isNil(newProfessionsSpecialties)) return []

	// Map old specialties
	const mappingOldSpecialty = flatMap(Object.entries(oldProfessionsSpecialties || {}), ([key, value]) => {
		return value.length > 0
			? value.map((item) => ({ ...item, key }))
			: [
					{
						key,
						id: null,
						isPrimary: false,
						parentId: null,
					},
			  ]
	})

	// Map new specialties
	const mappingNewSpecialty = Object.entries(newProfessionsSpecialties || {}).flatMap(([key, value]) => {
		return value.length > 0
			? value.map((item) => ({ ...item, key }))
			: [
					{
						key,
						id: null,
						isPrimary: false,
					},
			  ]
	})

	let allSpecialtyChanges = []

	// Handle primary specialty changes
	const oldPrimarySpecialty = mappingOldSpecialty?.find((item) => item.isPrimary === true)
	const newPrimarySpecialty = mappingNewSpecialty?.find((item) => item.isPrimary === true)

	if (hasPrimarySpecialtyChanged(oldPrimarySpecialty, newPrimarySpecialty)) {
		allSpecialtyChanges.push(...createPrimarySpecialtyChanges(oldPrimarySpecialty, newPrimarySpecialty))
	}

	// Handle new items
	const newItems = differenceBy(mappingNewSpecialty, mappingOldSpecialty, (item) => item?.key).map((item) => ({
		disciplineId: item.key,
		specialtyId: item.id,
		isPrimary: item.isPrimary || false,
		id: item.parentId,
	}))

	// Handle removed items
	const removedItems = differenceBy(mappingOldSpecialty, mappingNewSpecialty, (item) => item?.key).map((item) => ({
		id: item.parentId,
		disciplineId: item.key,
		specialtyId: item.id,
		isPrimary: false,
		_destroy: true,
	}))

	allSpecialtyChanges = [...allSpecialtyChanges, ...newItems, ...removedItems]

	// Remove duplicates and process
	return removeDuplicateSpecialties(allSpecialtyChanges)
}
