import { Box, Grid, SvgIcon } from '@material-ui/core'
import { FieldArray, Form, Formik } from 'formik'
import React, { useEffect, useRef } from 'react'
import { useEffectOnce } from 'react-use'
import { useWindowScrollTop } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { loadingState, observer } from '~/common/mobx.decorator'
import * as yup from 'yup'
import moment from 'moment'
import { FormMode } from '@opus/web.core.form.utils'
import { PlusBlueSvg } from '~/components/icons'
import { EducationForm } from '../../components/education-form'
import { eduTabStore } from './edu-tab.store'
import { Button } from '~/components/button'
import { eventClient } from '@opus/web.core.lib.event-tracking'
import SectionBox from '~/components/section-box'
import { Typography } from '~/components/typography'
import HintBox from '~/features/care-activation/components/hint-box'
import { logClickEvent, logDefaultActionEvent, logTrackingScreenEvent } from '~/common/tracking/event-client.tracking'
import { EDUCATION_TRACKING_EVENT } from '~/common/constants'
import { differenceBy, isEmpty } from 'lodash'
import { masterStore } from '~/stores'
import { useStyles } from '~/features/care-profile/tabs/info/info-tab.style'

const validateSchema = yup.object({
	educations: yup
		.array()
		.of(
			yup.object({
				schoolName: yup.string().trim().required('Please enter school or university').max(60, 'Maximum is 60 characters'),
				city: yup
					.string()
					.trim()
					.when('state', {
						is: (state) => {
							const hasCities = !isEmpty(state) ? masterStore.workLocations?.find((location) => location.value === state)?.hasCities : true
							return hasCities
						},
						then: yup.string().required('Please select your city').typeError('Please select your city'),
						otherwise: yup.string().nullable(),
					}),
				state: yup.string().trim().required('Please select your state').typeError('Please select your state'),
				major: yup.string().trim().required('Please enter major').max(60, 'Maximum is 60 characters'),
				degreeName: yup.string().trim().required('Please select degree').max(60, 'Maximum is 60 characters'),
				graduationDate: yup
					.date()
					.max(moment().add(10, 'y').toDate(), 'Graduation date can’t be in the future')
					.required('Please select graduation date')
					.typeError('Please select graduation date'),
			})
		)
		.min(1, 'Please add at least one education history'),
})

const EducationViewForm = observer(({ initialValues = {}, onSubmit, onPreviousTab }) => {
	const { t } = useTranslation()
	const addButtonRef = useRef()
	const formRef = useRef()
	const classes = useStyles()

	const loading = loadingState('eduTabStore/updateProfileEducation')

	const handleNextTab = (e) => {
		logClickEvent(EDUCATION_TRACKING_EVENT.CLICK_NEXT_STEP_BTN, {})
		onSubmit(e)
	}

	return (
		<Formik
			innerRef={formRef}
			initialValues={{ educations: initialValues }}
			enableReinitialize={true}
			onSubmit={handleNextTab}
			validationSchema={validateSchema}
		>
			{({ handleSubmit, values, errors }) => {
				return (
					<Form onSubmit={handleSubmit}>
						<FieldArray name="educations">
							{({ push, remove }) => (
								<Box>
									{values.educations && values.educations.length > 0 ? (
										values.educations.map((item, index) => (
											<SectionBox
												minimumRemove={values.educations?.length}
												minimumRemoveDialogTitle={t('$MESSAGES.MINIMUM_EDUCATION_REMOVE')}
												header={`${t('EDUCATION')}`}
												onRemove={() => {
													eventClient.logClickEvent(EDUCATION_TRACKING_EVENT.CLICK_REMOVE_EDUCATION_BTN, {
														_destroy: item?.['_destroy'] || false,
														city: item.city,
														country: item.country,
														degree_name: item.degreeName,
														graduation_date: item.graduationDate,
														id: item?.id || null,
														major: item.major,
														school_name: item.schoolName,
														state: item.state,
													})
													remove(index)
												}}
												showItemRemove
												hasBorder
											>
												<Box m={2}>
													<EducationForm mode={FormMode.Edit} formName="educations" index={index} />
												</Box>
											</SectionBox>
										))
									) : (
										<Box display="flex" justifyContent="center" my={1}>
											<Typography color="textSecondary">{t('$ERRORS.EMPTY_EDUCATIONS')}</Typography>
										</Box>
									)}

									<Button
										ref={addButtonRef}
										variant="contained"
										color="primary.main"
										startIcon={<SvgIcon className={classes.addIcon} component={PlusBlueSvg} viewBox="0 0 18 18"></SvgIcon>}
										className={classes.addSectionBtn}
										onClick={() => {
											const nullEducation = {
												city: null,
												state: null,
												country: '',
												schoolName: '',
												degreeName: '',
												major: '',
												graduationDate: null,
											}

											push(nullEducation)
											eventClient.logClickEvent(EDUCATION_TRACKING_EVENT.CLICK_ADD_EDUCATION_BTN, {})
										}}
									>
										{t('ADD_EDUCATION')}
									</Button>
								</Box>
							)}
						</FieldArray>

						<Box mt={2}>
							<Grid container spacing={2}>
								<Grid item xs={12} md={6}>
									<Button
										fullWidth
										variant="outlined"
										color="primary"
										onClick={() => {
											logClickEvent(EDUCATION_TRACKING_EVENT.CLICK_PREVIOUS_STEP_BTN, {})
											onPreviousTab()
										}}
									>
										{t('PREVIOUS_STEP')}
									</Button>
								</Grid>
								<Grid item xs={12} md={6}>
									<Button loading={loading} type="submit" fullWidth variant="contained" color="primary">
										{t('SAVE_AND_NEXT_STEP')}
									</Button>
								</Grid>
							</Grid>
						</Box>
					</Form>
				)
			}}
		</Formik>
	)
})

const EducationSection = observer(({ onNextTab, onPreviousTab }) => {
	const { educations, updateProfileEducation, workerEducations } = eduTabStore
	useEffectOnce(() => {
		logTrackingScreenEvent(EDUCATION_TRACKING_EVENT.SCREEN_TRACKING, { is_start_screen_session: true })
	})

	const handleSubmitEducation = async (values) => {
		const newValues = values.educations.map((item) => ({
			id: item.id,
			schoolName: item.schoolName,
			major: item.major,
			degreeName: item.degreeName,
			graduationDate: item.graduationDate,
			state: item?.state ?? null,
			city: item?.city?.name ?? null,
			country: 'US',
		}))

		const removedValues = differenceBy(workerEducations, newValues, (item) => item.id)
		const submitValues = [...newValues, ...removedValues?.map((item) => ({ ...item, _destroy: true }))]

		await updateProfileEducation(submitValues)
			.then(() => {
				logDefaultActionEvent(EDUCATION_TRACKING_EVENT.PROFILE_COMPLETION_EDUCATIONS_SUBMIT_SUCCESS, {
					worker_educations: submitValues.map((item) => {
						return {
							_destroy: item?.['_destroy'] || false,
							city: item.city,
							country: item.country,
							degree_name: item.degreeName,
							graduation_date: item.graduationDate,
							id: item?.id || null,
							major: item.major,
							school_name: item.schoolName,
							state: item.state,
						}
					}),
				})
			})
			.catch(() => {
				logDefaultActionEvent(EDUCATION_TRACKING_EVENT.PROFILE_COMPLETION_EDUCATIONS_SUBMIT_FAILED, {
					worker_educations: submitValues.map((item) => {
						return {
							_destroy: item?.['_destroy'] || false,
							city: item.city,
							country: item.country,
							degree_name: item.degreeName,
							graduation_date: item.graduationDate,
							id: item?.id || null,
							major: item.major,
							school_name: item.schoolName,
							state: item.state,
						}
					}),
				})
			})

		onNextTab()
	}

	return (
		<>
			<Box>
				<EducationViewForm onSubmit={handleSubmitEducation} initialValues={educations} onPreviousTab={onPreviousTab} />
			</Box>
		</>
	)
})

export const EduTab = observer(({ hintText, onNextTab, onPreviousTab }) => {
	const { fetchWorkerEducations, addNewEducation, educations } = eduTabStore

	useWindowScrollTop()

	useEffectOnce(() => {
		void fetchWorkerEducations()
	}, [])

	const loading = loadingState('eduTabStore/fetchWorkerEducations')

	useEffect(() => {
		if (loading === false && !educations.length) {
			addNewEducation()
		}
	}, [loading, educations.length, addNewEducation])

	const handleNextTab = () => {
		logClickEvent(EDUCATION_TRACKING_EVENT.CLICK_SKIP_STEP_2, {})
		onNextTab()
	}

	return (
		<Box p={2}>
			<HintBox text={hintText} onSkipTab={handleNextTab} />

			<EducationSection onNextTab={onNextTab} onPreviousTab={onPreviousTab} />
		</Box>
	)
})
