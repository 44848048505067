import { FileChecksum } from '@rails/activestorage/src/file_checksum'
import { BlobUpload } from '@rails/activestorage/src/blob_upload'
import { apolloClient, CREATE_DIRECT_UPLOAD_MUTATION, DESTROY_FILE_MUTATION } from '../apollo'
import Compressor from 'compressorjs'
import { captureException } from './sentry.helper'
import { authStore, notifyStore } from '~/stores'
import { logDefaultActionEvent } from '~/common/tracking/event-client.tracking'
import { CORE_BEHAVIOR_TRACKING } from '~/common/tracking/event-click.constant'
import { quickApplyStore } from '~/features/quick-apply/quick-apply.store'

export const convertToBase64 = (file) => {
	return new Promise((resolve, reject) => {
		if (!file) {
			reject('File does not exist')
		}
		const reader = new FileReader()
		reader.onloadend = function () {
			resolve(reader.result)
		}
		reader.readAsDataURL(file)
	})
}

export const compressImage = (file) => {
	return new Promise((resolve, reject) => {
		if (file.size < 1 * 1000 * 1000) {
			resolve(file)
		}
		new Compressor(file, {
			quality: 0.6,
			success(result) {
				resolve(result)
			},
			error(err) {
				reject(err.message)
			},
		})
	})
}

export const calculateChecksum = (file) => {
	return new Promise((resolve, reject) => {
		FileChecksum.create(file, (error, checksum) => {
			if (error) {
				reject(error)
				return
			}

			resolve(checksum)
		})
	})
}

export const directUpload = (url, headers, file) => {
	const upload = new BlobUpload({ file, directUploadData: { url, headers } })
	return new Promise((resolve, reject) => {
		upload.create((error) => {
			if (error) {
				reject(error)
			} else {
				resolve('Completed')
			}
		})
	})
}

export const uploadFile = async (file) => {
	try {
		const { currentJobID } = quickApplyStore
		const checksum = await calculateChecksum(file)
		const { data } = await apolloClient().mutate({
			mutation: CREATE_DIRECT_UPLOAD_MUTATION,
			variables: { filename: file.name, byteSize: file.size, checksum: checksum, contentType: file.type },
			context: {
				clientName: authStore.id ? null : 'public',
			},
		})

		const { headers, url, signedBlobId, preSignedUrl, blobId } = data.createDirectUpload
		await directUpload(url, JSON.parse(headers), file)
		logDefaultActionEvent(CORE_BEHAVIOR_TRACKING.resumeUploadSuccess, {
			job_id: currentJobID,
			is_account_created: true, // true HERE
		})
		return { signedBlobId, preSignedUrl, blobId }
	} catch (e) {
		const { currentJobID } = quickApplyStore
		notifyStore.error(e.message)

		logDefaultActionEvent(CORE_BEHAVIOR_TRACKING.resumeUploadFailed, {
			job_id: currentJobID,
			is_account_created: true, // true HERE
		})
	}
}

export const removeFile = async (id) => {
	try {
		const { data } = await apolloClient().mutate({
			mutation: DESTROY_FILE_MUTATION,
			variables: { id },
		})

		return data.destroyFile?.success
	} catch (error) {
		captureException('File Helper', error)
		return false
	}
}
export const convertFileNameEllipsis = (fileName, maxLength = 12, cutOff = 8) => {
	let fileExt = fileName?.substring(fileName?.lastIndexOf('.') + 1)
	if (fileName?.length > maxLength) {
		fileName = fileName?.substring(0, cutOff) + '...' + fileExt
	}
	return fileName
}

export const reverseDataMapping = (array) => {
	return array?.map((val, index, array) => array[array.length - 1 - index])
}

export const blobToFile = async (theBlob, fileName) => {
	return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type })
}

export function getFileExtension(filename) {
	if (!filename) return
	return filename.slice(((filename.lastIndexOf('.') - 1) >>> 0) + 2)
}

// Helper function to trim all string values in an object
export function deepTrim(obj) {
	// Check if the input is an array
	if (Array.isArray(obj)) {
		return obj.map(deepTrim) // Recursively call deepTrim on each array element
	}
	// Check if the input is an object
	else if (obj !== null && typeof obj === 'object') {
		return Object.keys(obj).reduce((acc, key) => {
			acc[key] = deepTrim(obj[key]) // Recursively call deepTrim on each object property
			return acc
		}, {})
	}
	// If the input is a string, trim it
	else if (typeof obj === 'string') {
		return obj.trim()
	}
	// Return the value if it is not an object, array, or string

	return obj
}

export async function mutateWithTrimmedVariables({ mutation, variables }) {
	// Trim all string values in variables

	const trimmedVariables = deepTrim(variables)

	// Call apolloClient().mutate with trimmed variables
	const result = await apolloClient().mutate({
		mutation,
		variables: trimmedVariables,
	})
	return result
}
