import { filter, find, flatMap, isNil, omit } from 'lodash'
import { action, computed, observable, store } from '~/common/mobx.decorator'
import { masterStore, notifyStore } from '~/stores'
import { createWorkerEducation, DESTROY_WORKER_EDU_MUTATION, getWorkerEducations } from '../../care-activation.service'
import { apolloClient } from '~/common/apollo'
import { UPDATE_PROFILE_EDUCATION_MUTATION, UPDATE_WORKER_EDU_MUTATION } from '~/features/care-profile/care-profile.service'
import { mutateWithTrimmedVariables } from '~/common/helpers'

@store()
class EduTabStore {
	@observable workExperiences
	@observable workerEducations = []
	@observable submitEducationsValid = {}

	@observable educationCreating = false

	@observable educationUpdating = false
	@observable educationCurrentId = ''
	@action
	setEducationCurrentId = (flag) => {
		this.educationCurrentId = flag
	}
	@action
	setEducationUpdating = async (flag) => {
		this.educationUpdating = flag
	}
	@computed
	get educations() {
		return (
			this.workerEducations
				?.map(({ city, state, country, ...education }) => ({
					...education,
					city: city ? { country, state, city, stateCode: state, name: city } : null,
					state,
				}))
				?.filter((item) => !item._destroy) || []
		)
	}

	@computed
	get unitOptions() {
		const options = find(masterStore.disciplines, { value: 'RN' })
		return options?.dependent_values || []
	}

	@computed
	get floatedUnitOptions() {
		const options = this.unitOptions.map((option) =>
			this.unitOptions.map((o) => ({
				...o,
				parentValue: option.value,
			}))
		)

		return filter(flatMap(options), (option) => option.parentValue !== option.value)
	}

	@computed
	get agencyOptions() {
		return masterStore.agencies
	}

	@computed
	get chartingOptions() {
		return masterStore.chartings
	}

	@action
	fetchWorkerEducations = async () => {
		this.workerEducations = await getWorkerEducations()
	}

	@action
	handleSubmitEducation = async ({ location, ...data }) => {
		try {
			const education = { ...data, city: location.city, state: location.state }
			await createWorkerEducation(education)
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	@action
	setEducationCreating = async (flag) => {
		this.educationCreating = flag
	}
	@action
	handleRemoveClick = async (flag) => {
		return null
	}

	// education data handler
	@action
	addNewEducation = () => {
		const nullEducation = {
			city: null,
			state: null,
			country: '',
			schoolName: '',
			degreeName: '',
			major: '',
			graduationDate: null,
		}
		this.workerEducations = [...this.workerEducations, nullEducation]
	}

	@action
	removeEducation = (education) => {
		this.submitEducationsValid = omit(this.submitEducationsValid, education.id ?? education.tempId)

		if (!education.id) {
			this.workerEducations = this.workerEducations.filter((item) => item.tempId !== education.tempId)

			return
		}

		this.workerEducations = this.workerEducations.map((item) => {
			if (item.id === education.id) {
				return {
					...item,
					_destroy: true,
				}
			}

			return item
		})
	}

	@action
	syncEducationsValid = (id, isValid) => {
		this.submitEducationsValid = { ...this.submitEducationsValid, [id]: isValid }
	}

	@action
	syncEducation = (newContact) => {
		this.workerEducations = this.workerEducations.map((item) => {
			if (!isNil(item.tempId) && !isNil(newContact.tempId) && item.tempId === newContact.tempId) {
				return {
					...newContact,
					...newContact.location,
				}
			}

			if (!isNil(item.id) && !isNil(newContact.id) && item.id === newContact.id) {
				return {
					...newContact,
					...newContact.location,
				}
			}

			return item
		})
	}

	@action updateEducation = async (values) => {
		try {
			await mutateWithTrimmedVariables({
				mutation: UPDATE_WORKER_EDU_MUTATION,
				variables: {
					...values,
				},
			})
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action updateProfileEducation = async (values) => {
		try {
			await mutateWithTrimmedVariables({
				mutation: UPDATE_PROFILE_EDUCATION_MUTATION,
				variables: {
					workerEducations: [...values],
				},
			})
		} catch (error) {
			notifyStore.error(error.message)
		}
	}

	@action destroyEducation = async (id) => {
		try {
			await apolloClient().mutate({
				mutation: DESTROY_WORKER_EDU_MUTATION,
				variables: {
					destroyWorkerEducationId: id,
				},
			})
		} catch (error) {
			notifyStore.error(error.message)
		}
	}
}

export const eduTabStore = new EduTabStore()
