import React, { Suspense, useEffect, useMemo, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { Backdrop, CircularProgress, CssBaseline, NoSsr, ThemeProvider as MuiThemeProvider } from '@material-ui/core'
import { LocalizationProvider } from '@material-ui/pickers'
import { LanguageProvider } from '@opus/web.core.hooks.use-translation'
import { AppRoute } from './app.route'
import { Router } from 'react-router-dom'
import { appStore, authStore, history } from '~/stores'
import { loadingState, observer } from '~/common/mobx.decorator'
import { GlobalStyle, useStyles } from './app.style'
import { ApolloProvider } from '@apollo/client'
import { initializeApolloClient } from '~/common/apollo'
import { SnackbarProvider } from 'notistack'
import { GlobalNotify } from '~/components/notify'
import dateAdapter from '@material-ui/pickers/adapter/moment'
import moment from 'moment'
import { ACTIONS } from './common/constants'
import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import LanguagePlural from 'i18next-intervalplural-postprocessor'
import { initReactI18next } from 'react-i18next'
import { setLocale } from 'yup'
import { CommonChangePasswordDialogFeature } from '~/features/common-change-password/common-change-password-dialog.feature'
import { CoreProvider } from '~/core/providers'
import { auditService, authService, dataService, permissionService } from '~/services'
import { useInterval } from 'ahooks'
import { ChatEngine } from '~/components/chat-engine'
import { careStore } from '~/companies/care/care.store'
import { ErrorContent } from '~/components/error-content'

const initI18next = (theme = 'crossmed') => {
	return i18next
		.use(Backend)
		.use(LanguageDetector)
		.use(LanguagePlural)
		.use(initReactI18next)
		.init(
			{
				fallbackLng: 'en',
				lng: 'en',
				defaultNS: 'translation',
				fallbackNS: 'translation',
				ns: ['translation', 'validation', 'common'],

				interpolation: {
					escapeValue: false,
				},

				backend: {
					loadPath: `/locales/${theme}/{{lng}}/{{ns}}.json`,
					addPath: `/locales/${theme}/{{lng}}/{{ns}}`,
					queryStringParams: {
						version: process.env.REACT_APP_CI_COMMIT_TAG,
					},
				},
			},
			(_, t) => {
				const locale = t('$VALIDATION', { returnObjects: true })
				setLocale(locale)
			}
		)
}

// i18next
// 	.use(Backend)
// 	.use(LanguageDetector)
// 	.use(LanguagePlural)
// 	.use(initReactI18next) // bind react-i18next to the instance
// 	.init(
// 		{
// 			fallbackLng: 'en',
// 			lng: 'en',
// 			defaultNS: 'translation',
// 			fallbackNS: 'translation',

// 			interpolation: {
// 				escapeValue: false, // not needed for react!!
// 			},
// 			backend: {
// 				loadPath: '/locales/{{lng}}/{{ns}}.json',
// 				addPath: '/locales/add/{{lng}}/{{ns}}',
// 				queryStringParams: { version: process.env.REACT_APP_CI_COMMIT_TAG },
// 			},
// 		},
// 		(_, t) => {
// 			const locale = t('$VALIDATION', { returnObjects: true })
// 			setLocale(locale)
// 		}
// 	)

moment.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: 'a few seconds',
		ss: '%d seconds',
		m: '1 minute',
		mm: '%d minutes',
		h: '1 hour',
		hh: '%d hours',
		d: '1 day',
		dd: '%d days',
		w: '1 week',
		ww: '%d weeks',
		M: '1 month',
		MM: '%d months',
		y: '1 year',
		yy: '%d years',
	},
})

export const App = observer(() => {
	const [apolloClient, setApolloClient] = useState(null)
	const loading = loadingState(ACTIONS.appStore.init)
	const classes = useStyles()
	const { ready, expiresAt, refreshAuthToken } = authStore
	const isRefreshingToken = loadingState('authStore/refreshAuthToken')
	const loadingPublicCompanyInfo = loadingState('careStore/fetchPublicPrivacyInfo')

	useEffect(() => {
		const theme = appStore.themeCode
		initI18next(theme)
		// eslint-disable-next-line
	}, [appStore.themeCode])

	useEffect(() => {
		if (!ready || !appStore.apiUrl) {
			return
		}
		void appStore.init()
		careStore.fetchPublicPrivacyInfo()
		// eslint-disable-next-line
	}, [ready, appStore.apiUrl])

	useInterval(() => {
		if (expiresAt === 0 || expiresAt - Date.now() > 0 || isRefreshingToken) {
			return
		}
		void refreshAuthToken()
	}, 2000)
	// eslint-disable-next-line
	const themeInit = useMemo(() => appStore.getTheme, [appStore.themeCode, appStore.appTheme])

	useEffect(() => {
		initializeApolloClient().then((client) => {
			setApolloClient(client)
		})
	}, [])

	if (!apolloClient || !themeInit || loading) {
		return (
			<Backdrop open={true}>
				<CircularProgress />
			</Backdrop>
		)
	}

	return (
		<CoreProvider authService={authService} dataService={dataService} auditService={auditService} permissionService={permissionService}>
			<Router history={history}>
				<Suspense
					fallback={
						<Backdrop open={true}>
							<CircularProgress />
						</Backdrop>
					}
				>
					<NoSsr>
						<ApolloProvider client={apolloClient}>
							<LanguageProvider value="care">
								<MuiThemeProvider theme={themeInit}>
									<ThemeProvider theme={themeInit}>
										<SnackbarProvider
											maxSnack={3}
											preventDuplicate
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											classes={{ root: classes.root }}
										>
											<LocalizationProvider dateAdapter={dateAdapter}>
												<CssBaseline />
												<GlobalStyle />
												<GlobalNotify />
												{!loadingPublicCompanyInfo && careStore.chatIntegrationSetting && <ChatEngine />}
												{appStore.showError ? <ErrorContent /> : ready && <AppRoute />}
												<CommonChangePasswordDialogFeature
													showChangePasswordDialg={authStore.showChangePasswordDialog}
													setShowChangePasswordDialog={authStore.setShowChangePasswordDialog}
												/>
											</LocalizationProvider>
										</SnackbarProvider>
									</ThemeProvider>
								</MuiThemeProvider>
							</LanguageProvider>
						</ApolloProvider>
					</NoSsr>
				</Suspense>
			</Router>
		</CoreProvider>
	)
})
