import React from 'react'
import { Box, IconButton, SvgIcon, Typography } from '@material-ui/core'
import { filenameStyle, itemBoxBorderedStyle, itemBoxStyle, uploadTextStyle } from '~/components/fields/file-upload-field/file-upload-field.style'
import { CheckedCircleGreenSvg, FileUploadedSvg, TrashSvg } from '~/components/icons'
import { FIELD_MODE } from '~/common/constants'

function UploadListDoc(props) {
	const isRenderEdit = props.mode === FIELD_MODE.edit || (props.mode === FIELD_MODE.viewDisable && props?.item.hasOwnProperty('signedBlobId'))
	const { isAllowShowCtaDownload, onClickDownload } = props
	return (
		<>
			<Box
				px={2}
				py={1}
				height={'60px'}
				css={[itemBoxStyle, props.hasBorder ? itemBoxBorderedStyle : null]}
				onClick={props.onClick}
				key={props?.key}
				className="upload-list"
			>
				<Box display="flex" alignItems="center" flexGrow={1} gridGap={8}>
					<SvgIcon
						style={
							props.mode === FIELD_MODE.edit
								? { width: '36px', height: '36px' }
								: {
										width: '24px',
										height: '24px',
								  }
						}
						component={FileUploadedSvg}
						viewBox="0 0 36 36"
					/>
					<Box pr={1}>
						<Typography css={filenameStyle} variant="subtitle2">
							{props.item?.filename}
						</Typography>
						{!props.hideUploadedStatus && (
							<Box className="upload-icon" mt="4px">
								<CheckedCircleGreenSvg />
								<span css={uploadTextStyle}>Uploaded</span>
							</Box>
						)}
					</Box>
				</Box>
				{isRenderEdit && !props.hideDelete && (
					<IconButton size="small" onClick={props.onClick1}>
						<TrashSvg />
					</IconButton>
				)}
				{props.invisibleUploadInput && (
					<IconButton style={{ display: 'none' }} size="small" onClick={props.onClick1} id="resumes_file_removed">
						<TrashSvg />
					</IconButton>
				)}
				{isAllowShowCtaDownload && (
					<IconButton
						className="download-btn"
						disableRipple
						disableTouchRipple
						onClick={() => {
							onClickDownload && onClickDownload()
							return window.open(props?.item?.files[0]?.fileUrl, '_blank')
						}}
					>
						<img className={'download-icon'} src={'/assets/download.png'} alt="download-icon" width={16} height={16} />
						<Typography className="text" color="secondary">
							Download
						</Typography>
					</IconButton>
				)}
			</Box>
		</>
	)
}

export default UploadListDoc
