import { hintBoxStyle, hintBoxPercentageStyle, skipStepStyle } from '~/features/care-activation/care-activation.style'
import { Box, Typography, Link } from '@material-ui/core'
import React from 'react'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { CircularPercentage } from '~/components/circular-percentage/circular-percentage.component'
import { Button } from '~/components/button'

const HintBox = ({ text, percentage = null, onSkipTab }) => {
	const { t } = useTranslation()
	if (percentage) {
		return (
			<Box p={2} mb={2} css={hintBoxPercentageStyle} gridGap={8}>
				<div className="text-container">
					<Typography variant="body2">{text}</Typography>
					{onSkipTab && (
						<Link variant="text" color="primary" size="small" css={skipStepStyle} onClick={() => typeof onSkipTab === 'function' && onSkipTab()}>
							{t('DO_IT_LATER')}
						</Link>
					)}
				</div>
				<div>
					<CircularPercentage thickness={5.5} value={percentage} size={33} />
				</div>
			</Box>
		)
	}
	return (
		<Box display="flex" alignItems="center" justifyContent="space-between" p={2} mb={2} css={hintBoxStyle} gridGap={8}>
			<Typography variant="body2">{text}</Typography>
			{onSkipTab && (
				<Button variant="text" color="secondary.main" size="small" css={skipStepStyle} onClick={() => typeof onSkipTab === 'function' && onSkipTab()}>
					{t('DO_IT_LATER')}
				</Button>
			)}
		</Box>
	)
}

export default HintBox
