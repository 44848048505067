import ProfessionBox from '~/features/care-profile/components/profession-box'
import React, { forwardRef, useEffect, useState } from 'react'
import { differenceBy, isEmpty } from 'lodash'
import { mappingItemPicklistSpecialty } from '~/common/helpers/mapping.helper'
import { Form, Formik } from 'formik'
import { FormMode } from '@opus/web.core.form.utils'
import { masterStore } from '~/stores'
import { FIELD_MODE, PROFESSION_DIALOG_STEP } from '~/common/constants'
import { useToggle } from 'react-use'
import ActionDialog from '~/components/action-dialog'
import { Button } from '~/components/button'
import { AutoCompleteField } from '~/components/fields'
import { Box, Fade, FormHelperText, SvgIcon } from '@material-ui/core'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { useStyles } from '~/features/care-profile/tabs/info/info-tab.style'
import { PlusCircleBlueSvg } from '~/components/icons'
import { loadingState, observer } from '~/common/mobx.decorator'
import { infoTabStore } from '~/features/care-activation/tabs/info/info-tab.store'
import { logClickEvent } from '~/common/tracking/event-client.tracking'
import { ACTIATION_TRACKING_CONSTANT } from '../care-activation.constant'

function moveToFirst(arr, item) {
	const index = arr.indexOf(item)
	if (index !== -1) {
		const removed = arr.splice(index, 1)
		return [...removed, ...arr]
	}
	return arr
}

const Transition = forwardRef(function Transition(props, ref) {
	return <Fade ref={ref} {...props} />
})
const ProfessionForm = observer(({ id = 'profession-form', mode = FormMode.Edit, handleSubmit, showSubmit, showErrorCode = false, setShowErrorCode }) => {
	const {
		professionInitialValues,
		groupedSpecialties,
		syncPersonalInfo,
		submitPersonalInfo,
		hasAssignedRecruiterOrManager,
		setProfessionInfo: updateProfessionInfo,
	} = infoTabStore
	const { disciplinesData } = masterStore
	const [showDialog, toggleDialog] = useToggle(false)
	const [dialogStep, setDialogStep] = useState(PROFESSION_DIALOG_STEP.professions)
	const handleCloseProfessionDialog = () => {
		setDialogStep(PROFESSION_DIALOG_STEP.professions)
		toggleDialog(false)
	}
	const [professionInfo, setProfessionInfo] = useState(groupedSpecialties)
	// how to check the key has value isPrimary = true from professionInfo and return this key
	const primaryProfession = Object.keys(professionInfo || {}).find((item) => professionInfo[item].find((item) => item.isPrimary))

	//how to get the value of name  from professionInfo with key isPrimary = true and return it name
	const primarySpecialty = professionInfo[primaryProfession]?.find((item) => item.isPrimary) || {}
	const { t } = useTranslation()
	const classes = useStyles()

	const removeProfession = async (profession) => {
		setProfessionInfo((prev) => {
			const newProfessionInfo = { ...prev }
			delete newProfessionInfo[profession]
			return newProfessionInfo
		})
	}

	const removeSpecialty = async (profession, specialty) => {
		setProfessionInfo((prev) => {
			const newProfessionInfo = { ...prev }
			newProfessionInfo[profession] = newProfessionInfo[profession].filter((item) => item.id !== specialty)
			return newProfessionInfo
		})
	}

	const setPrimaryProfession = async (profession) => {
		setProfessionInfo((prev) => {
			const newProfessionInfo = { ...prev }
			if (newProfessionInfo[primaryProfession]) {
				newProfessionInfo[primaryProfession].forEach((item) => {
					item.isPrimary = false
				})
			} else {
				console.warn(`newProfessionInfo[${primaryProfession}] is undefined`)
			}
			if (!newProfessionInfo[profession]) {
				newProfessionInfo[profession] = []
			}

			// Check if the array has at least one element
			if (newProfessionInfo[profession].length === 0) {
				// Initialize the first element as an empty object
				newProfessionInfo[profession][0] = {}
			}
			newProfessionInfo[profession][0].isPrimary = true
			return newProfessionInfo
		})
	}

	const setPrimarySpecialty = async (profession, specialty) => {
		setProfessionInfo((prev) => {
			const newProfessionInfo = { ...prev }
			newProfessionInfo[profession].forEach((item) => {
				item.isPrimary = false
			})
			newProfessionInfo[profession].find((item) => item.id === specialty.id).isPrimary = true
			return newProfessionInfo
		})
	}

	const handleUpdate = (profession, specialties) => {
		const professionData = Object.keys(professionInfo || {})?.find((item) => item === profession)
		const specialty = mappingItemPicklistSpecialty(
			disciplinesData?.find((item) => item.value === profession)?.specialties.filter((item) => specialties.includes(item.id))
		)

		if (!professionData && isEmpty(professionInfo)) {
			setProfessionInfo((prev) => {
				const newProfessionInfo = { ...prev }
				//custom fist item of array specialty is object isPrimary = true
				newProfessionInfo[profession] = specialty.map((item, index) => {
					if (index === 0) {
						return {
							...item,
							isPrimary: true,
						}
					}
					return item
				})
				return newProfessionInfo
			})
		} else if (!professionData) {
			setProfessionInfo((prev) => {
				const newProfessionInfo = { ...prev }
				newProfessionInfo[profession] = specialty
				return newProfessionInfo
			})
		} else {
			setProfessionInfo((prev) => {
				const newProfessionInfo = { ...prev }
				newProfessionInfo[profession] = [...newProfessionInfo[profession], ...specialty]
				return newProfessionInfo
			})
		}
	}

	const loadingAddProfession = loadingState('infoTabStore/addProfessions')

	const handleAddSpecialties = () => {
		logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_ADD_SPECIALTIES_BTN_PROFILE_COMPLETION_PERSONAL_INFO)
		toggleDialog(true)
		setDialogStep(PROFESSION_DIALOG_STEP.specialties)
	}
	useEffect(() => {
		syncPersonalInfo({ ...submitPersonalInfo, professionInfo })
		updateProfessionInfo(professionInfo)
		// eslint-disable-next-line
	}, [professionInfo]) // Add professionInfo as a dependency

	const handleAddProfessions = async (profession, specialty) => {
		if (handleUpdate) {
			const professionTemp = Object.keys(professionInfo || {}).find((item) => item.profession === profession)
			if (professionTemp) await handleUpdate(profession, [...professionTemp?.specialties, ...specialty])
			else {
				await handleUpdate(profession, [...specialty])
			}
		}

		handleCloseProfessionDialog()
	}
	const checkExistEmptySpecialty = !isEmpty(primarySpecialty)

	useEffect(() => {
		if (!!professionInfo) {
			setShowErrorCode(false)
		}
		// eslint-disable-next-line
	}, [checkExistEmptySpecialty, professionInfo])

	const getSpecialties = (profession) => {
		if (!profession) return []
		return professionInfo[Object.keys(professionInfo || {})?.find((item) => item === profession)] || []
	}

	const mapItemList = moveToFirst(Object.keys(professionInfo || {}), primaryProfession)

	return (
		<Formik initialValues={professionInitialValues} enableReinitialize={true} onSubmit={handleSubmit} initialTouched={{ discipline: true, specialty: true }}>
			{({ handleSubmit, isSubmitting, initialValues, values, setFieldValue, setFieldError, errors, setTouched }) => {
				// Filter specialty options with current selected specialties
				const disciplineOptions = differenceBy(
					disciplinesData,
					Object.keys(professionInfo || {})?.map((item) => ({ value: item })),
					(option) => option.value
				)
				const specialtiesTemps = getSpecialties(values?.discipline)
				let specialtyOptions = !!values.discipline
					? differenceBy(
							mappingItemPicklistSpecialty(disciplinesData?.find((item) => item.value === values?.discipline)?.specialties),
							(specialtiesTemps ?? []).map((item) => ({ value: item?.id })),
							(option) => option.value
					  )
					: []
				specialtyOptions = !!specialtyOptions.find((item) => item.name === 'Undefined') ? [] : specialtyOptions
				return (
					<>
						<Form id={id} onSubmit={handleSubmit}>
							<>
								{professionInfo &&
									mapItemList?.map((item) => {
										return (
											<ProfessionBox
												primarySpecialtyInfo={primarySpecialty}
												primaryProfession={primaryProfession}
												key={item}
												isPrimary={item === primaryProfession}
												mode={mode}
												disciplinesData={disciplinesData}
												primarySpecialty={primarySpecialty?.name || ''}
												profession={item}
												groupedSpecialties={professionInfo[item].filter((item) => item.id !== primarySpecialty?.id)}
												onAddSpecialties={() => {
													setFieldValue('discipline', item)
													handleAddSpecialties()
												}}
												onRemoveProfession={removeProfession}
												onRemoveSpecialty={removeSpecialty}
												onSetPrimarySpecialty={setPrimarySpecialty}
												onSetPrimaryProfession={setPrimaryProfession}
												hasAssignedRecruiterOrManager={hasAssignedRecruiterOrManager}
											/>
										)
									})}
							</>

							{mode === FIELD_MODE.edit ? (
								!!professionInfo.profession ? (
									<></>
								) : (
									!hasAssignedRecruiterOrManager && (
										<Button
											variant="contained"
											color="primary.main"
											className={classes.addProfessionBtn}
											startIcon={<SvgIcon className={classes.addProfessionIcon} viewBox="0 0 20 20" component={PlusCircleBlueSvg} />}
											onClick={() => {
												logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_ADD_DISCIPLINE_BTN_PROFILE_COMPLETION_PERSONAL_INFO)
												toggleDialog(true)
											}}
										>
											{t('ADD_PROFESSION')}
										</Button>
									)
								)
							) : (
								<></>
							)}
							{showErrorCode && (
								<FormHelperText style={{ color: '#FF2727', fontSize: 13, fontWeight: 600 }}>Please select your profession and specialty</FormHelperText>
							)}

							{showSubmit && (
								<Box mt={3}>
									<Button loading={isSubmitting} type="submit" eventId="submit-profession_btn">
										{t('UPDATE')}
									</Button>
								</Box>
							)}
						</Form>

						{dialogStep === PROFESSION_DIALOG_STEP.professions && (
							<ActionDialog
								TransitionComponent={Transition}
								dialogTitle={t('ADD_PROFESSION')}
								onInit={() => setFieldValue('discipline', null)}
								showDialog={showDialog}
								showClose
								onClose={handleCloseProfessionDialog}
								dialogActions={
									<Button
										loading={loadingAddProfession}
										disabled={loadingAddProfession}
										onClick={() => {
											if (!values.discipline) {
												setFieldError('discipline', t('$ERRORS.SELECT_PROFESSION'))
												return
											}
											isEmpty(specialtyOptions) ? void handleAddProfessions(values.discipline, []) : setDialogStep(PROFESSION_DIALOG_STEP.specialties)
										}}
									>
										{t('DONE')}
									</Button>
								}
								className={classes.dialog}
							>
								<AutoCompleteField
									label="PROFESSION"
									name="discipline"
									options={disciplineOptions}
									mode={FIELD_MODE.edit}
									placeholder="$PLACEHOLDERS.SELECT_YOUR_PROFESSION"
								/>
							</ActionDialog>
						)}

						{dialogStep === PROFESSION_DIALOG_STEP.specialties && (
							<ActionDialog
								TransitionComponent={Transition}
								onInit={() => setFieldValue('specialty', [])}
								showClose
								dialogTitle={t('ADD_SPECIALTIES')}
								showDialog={showDialog}
								onClose={handleCloseProfessionDialog}
								dialogActions={
									<Button
										loading={loadingAddProfession}
										disabled={loadingAddProfession}
										onClick={async () => {
											if (!values.specialty?.length) {
												setFieldError('specialty', t('$ERRORS.SELECT_SPECIALTIES'))
												return
											}
											void handleAddProfessions(values.discipline, values.specialty)
										}}
									>
										{t('DONE')}
									</Button>
								}
								className={classes.dialog}
							>
								<AutoCompleteField
									label="SPECIALTIES"
									multiple
									name="specialty"
									options={specialtyOptions}
									mode={FIELD_MODE.edit}
									placeholder="$PLACEHOLDERS.SELECT_SPECIALTIES"
								/>
							</ActionDialog>
						)}
					</>
				)
			}}
		</Formik>
	)
})

export default ProfessionForm
