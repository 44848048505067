import { isArray, isEmpty, isNil, isObject, isString, omit } from 'lodash'
import { careFindJobStore } from '~/features/care-find-job/care-find-job.store'

export const ASSIGMENT_STATUS = [
	{ pendingStart: 'pending_start' },
	{ onboarding: 'onboarding' },
	{ cleared: 'cleared' },
	{ completed: 'completed' },
	{ approved: 'approved' },
	{ cancellation: 'cancellation' },
	{ termination: 'termination' },
	{ confirmed: 'confirmed' },
	{ notConfirmed: 'not_confirmed' },
]

export const TAG_CLASS = {
	success: 'success',
	cancel: 'cancel',
	pending: 'pending',
	start: 'start',
}
export const mappingFilterJobSearch = (searchData, isShowLocation = false) => {
	const searchDataFilter = {}
	if (searchData) {
		const {
			minMatchingPercentage,
			nearBy,
			shifts,
			disciplines,
			externalId,
			isActive,
			keyword,
			payAmountMin,
			location,
			workTypes,
			addressStates,
			addressCities,
			specialty,
			disciplineIds,
			startDate,
			specialtyIds,
			hotJob,
			specialties,
		} = searchData

		if (keyword) {
			searchDataFilter.keyword = keyword
		}

		if (externalId) {
			searchDataFilter.externalId = externalId
		} else {
			if (hotJob) {
				searchDataFilter.hotJob = hotJob
			}

			if (!isNil(minMatchingPercentage)) {
				searchDataFilter.minMatchingPercentage = minMatchingPercentage || 0
			}

			if (!isEmpty(shifts)) {
				searchDataFilter.shifts = shifts
			} else {
				searchDataFilter.shifts = null
			}

			if (!isEmpty(specialty) && isEmpty(specialties)) {
				searchDataFilter.specialtyIds = specialty.map((item) => item.value)
			}
			if (!isEmpty(specialties) && isEmpty(specialty)) {
				searchDataFilter.specialtyIds = specialties
			}

			if (!isEmpty(specialty) && !isEmpty(specialties)) {
				searchDataFilter.specialtyIds = specialties
			}

			if (!isEmpty(specialtyIds)) {
				if (specialtyIds?.every((item) => isObject(item) === true)) {
					searchDataFilter.specialtyIds = specialtyIds.map((item) => item.value)
				} else {
					searchDataFilter.specialtyIds = specialtyIds
				}
			}

			if (!isEmpty(disciplines)) {
				searchDataFilter.disciplineIds = disciplines.map((item) => item.value)
			}

			if (!isEmpty(disciplineIds)) {
				searchDataFilter.disciplineIds = disciplineIds
			}

			if (externalId) {
				searchDataFilter.externalId = externalId
			}
			if (!isEmpty(workTypes)) {
				searchDataFilter.workTypes = workTypes
			} else {
				searchDataFilter.workTypes = null
			}
			if (startDate) {
				if (isArray(startDate)) {
					searchDataFilter.startDate = {
						from: startDate[0],
						to: startDate[1],
					}
				} else {
					searchDataFilter.startDate = {
						from: startDate.from,
						to: startDate.to,
					}
				}
			}
			if (!isEmpty(addressStates)) {
				searchDataFilter.addressStates = addressStates
			} else {
				searchDataFilter.addressStates = null
			}
			if (!isEmpty(addressCities)) {
				searchDataFilter.nearBy = {
					lat: null,
					long: null,
					radius: !isNil(nearBy?.radius) ? nearBy?.radius : 50,
				}
			}
			if (!isEmpty(addressCities)) {
				searchDataFilter.addressCities = addressCities
			} else {
				searchDataFilter.addressCities = null
			}
			if (location) {
				const cities = location.filter((location) => location.type === 'locationCity').map((item) => item.value)
				const states = location.filter((location) => location.type === 'locationState').map((item) => item.value)

				if (!isEmpty(cities)) {
					searchDataFilter.addressCities = cities
				} else {
					searchDataFilter.addressCities = null
				}
				if (!isEmpty(states)) {
					searchDataFilter.addressStates = states
				} else {
					searchDataFilter.addressStates = null
				}

				if (cities.length >= 1 || states.length > 0) {
					const radiusValue = isNil(location) || location?.every((item) => item.type !== 'locationCity')
					if (!radiusValue) {
						if (nearBy) {
							searchDataFilter.nearBy = {
								lat: null,
								long: null,
								radius: !isNil(nearBy?.radius) ? nearBy?.radius : 50,
							}
						}
					}
				}
			}
			if (!isNil(payAmountMin)) {
				searchDataFilter.payAmountMin = payAmountMin
			}
			if (isActive) {
				searchDataFilter.isActive = isActive
			}
		}
	}
	const configValue = omit(searchDataFilter, ['location', 'isActive'])
	return configValue
}
export const mappingFilterJobSearchSubmit = (searchData, isShowLocation = false) => {
	const searchDataFilter = {}
	if (searchData) {
		const {
			minMatchingPercentage,
			nearBy,
			shifts,
			disciplines,
			externalId,
			isActive,
			keyword,
			payAmountMin,
			location,
			workTypes,
			addressStates,
			addressCities,
			specialty,
			disciplineIds,
			startDate,
			specialtyIds,
			hotJob,
			specialties,
			enableAlert,
		} = searchData

		if (keyword) {
			searchDataFilter.keyword = keyword
		}

		if (externalId) {
			searchDataFilter.externalId = externalId
		} else {
			if (enableAlert) {
				searchDataFilter.enableAlert = enableAlert
			}
			if (hotJob) {
				searchDataFilter.hotJob = hotJob
			}

			if (!isNil(minMatchingPercentage)) {
				searchDataFilter.minMatchingPercentage = minMatchingPercentage || 0
			}

			if (!isEmpty(shifts)) {
				searchDataFilter.shifts = shifts
			} else {
				searchDataFilter.shifts = null
			}

			if (!isEmpty(specialty) && isEmpty(specialties)) {
				searchDataFilter.specialtyIds = specialty.map((item) => item.value)
			}
			if (!isEmpty(specialties) && isEmpty(specialty)) {
				searchDataFilter.specialtyIds = specialties
			}

			if (!isEmpty(specialty) && !isEmpty(specialties)) {
				searchDataFilter.specialtyIds = specialties
			}

			if (!isEmpty(specialtyIds)) {
				if (specialtyIds?.every((item) => isObject(item) === true)) {
					searchDataFilter.specialtyIds = specialtyIds.map((item) => item.value)
				} else {
					searchDataFilter.specialtyIds = specialtyIds
				}
			}

			if (!isEmpty(disciplines)) {
				searchDataFilter.disciplineIds = disciplines.map((item) => item.value)
			}

			if (!isEmpty(disciplineIds)) {
				searchDataFilter.disciplineIds = disciplineIds
			}

			if (externalId) {
				searchDataFilter.externalId = externalId
			}
			if (!isEmpty(workTypes)) {
				searchDataFilter.workTypes = workTypes
			} else {
				searchDataFilter.workTypes = null
			}
			if (startDate) {
				if (isArray(startDate)) {
					searchDataFilter.startDate = {
						from: startDate[0],
						to: startDate[1],
					}
				} else {
					searchDataFilter.startDate = {
						from: startDate.from,
						to: startDate.to,
					}
				}
			}
			if (!isEmpty(addressStates)) {
				searchDataFilter.addressStates = addressStates
			} else {
				searchDataFilter.addressStates = null
			}
			if (!isEmpty(addressCities)) {
				searchDataFilter.nearBy = {
					lat: null,
					long: null,
					radius: !isNil(nearBy?.radius) ? nearBy?.radius : 50,
				}
			}
			if (!isEmpty(addressCities)) {
				searchDataFilter.addressCities = addressCities
			} else {
				searchDataFilter.addressCities = null
			}
			if (location) {
				const cities = location.filter((location) => location.type === 'locationCity').map((item) => item.value)
				const states = location.filter((location) => location.type === 'locationState').map((item) => item.value)

				if (!isEmpty(cities)) {
					searchDataFilter.addressCities = cities
				} else {
					searchDataFilter.addressCities = null
				}
				if (!isEmpty(states)) {
					searchDataFilter.addressStates = states
				} else {
					searchDataFilter.addressStates = null
				}

				if (cities.length === 1 || states.length > 0) {
					const radiusValue = isNil(location) || location?.every((item) => item.type !== 'locationCity')
					if (!radiusValue) {
						if (nearBy) {
							searchDataFilter.nearBy = {
								lat: null,
								long: null,
								radius: !isNil(nearBy?.radius) ? nearBy?.radius : 50,
							}
						}
					}
				}
			}
			if (!isNil(payAmountMin)) {
				searchDataFilter.payAmountMin = payAmountMin
			}
			if (isActive) {
				searchDataFilter.isActive = isActive
			}
		}
	}
	const configValue = omit(searchDataFilter, ['location', 'isActive'])
	return configValue
}
export const convertStateToArray = (data) => {
	return {
		...data,
		locationState: isString(data?.addressStates) ? data?.addressStates?.split(',') : data?.addressStates,
	}
}

export const convertStateToString = (data) => {
	return {
		...data,
		addressStates: data?.addressStates,
	}
}

export const mappingSkillName = (data) => {
	const skillNameLength = data?.specialties?.length
	const disciplines = data?.disciplines

	return (!!disciplines && skillNameLength === 0) || (!!disciplines && !skillNameLength)
		? {
				...data,
				specialtyIds: careFindJobStore.disciplineOptions(disciplines).map((item) => item?.value),
		  }
		: data
}

export const convertCommaSeparatedValuesToArray = (obj) => {
	for (let key in obj) {
		if (typeof obj[key] === 'string' && obj[key].includes(',')) {
			obj[key] = obj[key].split(',')
		}
	}
	return obj
}

export const convertToCorrectTypes = async (object) => {
	const allowedKeys = ['specialty', 'shifts', 'locationCity', 'locationState', 'disciplines', 'jobType']
	const values = Object.keys(object)
		.filter((key) => allowedKeys.includes(key))
		.reduce((filteredObj, key) => {
			filteredObj[key] = object[key]
			return filteredObj
		}, {})

	if (values.hasOwnProperty('locationState')) {
		const addressStates = values?.locationState?.split(',')
		if (addressStates.length > 1) {
			values.addressStates = addressStates
			delete values.locationCity
		} else {
			if (values.hasOwnProperty('locationCity')) {
				values.addressCities = [`${values?.locationCity}, ${values?.locationState}`]
			}
		}
	}
	if (values.hasOwnProperty('workType')) {
		values.workTypes = values.workType
	}

	values.shifts = values?.shifts?.split(',')
	if (values.hasOwnProperty('specialty')) {
		values.specialtyIds = values?.specialty?.split(',')
		delete values.specialty
	}

	return values
}

export const mappingObjectToArrayField = (obj) => {
	return Object.entries(obj).map(([key, value]) => {
		return {
			key,
			value,
		}
	})
}

export function convertToUSPhoneFormat(phoneNumber) {
	if (phoneNumber) {
		// Remove the leading '+1'
		let formattedNumber
		phoneNumber = phoneNumber.substring(2)
		// Remove any non-numeric characters from the input phone number
		phoneNumber = phoneNumber.replace(/\D+/g, '')
		// Check if the phone number is 10 digits or 7 digits
		if (phoneNumber.length === 10) {
			// Format the phone number as "xxx-xxx-xxxx"
			formattedNumber = phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
		} else if (phoneNumber.length === 9) {
			// Format the phone number as "xxx-xxx-xxx"
			formattedNumber = phoneNumber.replace(/(\d{3})(\d{4})/, '$1-$2')
		} else {
			// Return an error message for invalid phone numbers
			return 'Error: Invalid phone number'
		}
		return formattedNumber
	}
}

export function convertKeysToSnakeCase(obj) {
	if (typeof obj !== 'object' || obj === null) {
		return obj // Return as is if obj is not an object or is null
	}

	if (Array.isArray(obj)) {
		// If obj is an array, recursively convert keys for each item in the array
		return obj.map((item) => convertKeysToSnakeCase(item))
	}

	const snakeCaseObj = {}
	for (const key in obj) {
		if (obj.hasOwnProperty(key)) {
			// Convert key to snake case
			const snakeCaseKey = key.replace(/([A-Z])/g, '_$1').toLowerCase()
			// Recursively convert keys for nested objects
			snakeCaseObj[snakeCaseKey] = convertKeysToSnakeCase(obj[key])
		}
	}
	return snakeCaseObj
}

export function listBannerDismiss(banners) {
	let result = {}
	if (!!banners) {
		banners.forEach((banner) => {
			if (banner?.name === 'Welcome Banner') {
				result.welcomeBanner = {
					isDismissed: !banner?.isDismissed,
					id: banner?.id,
				}
			} else if (banner?.name === 'Referral Banner') {
				result.referralBanner = {
					isDismissed: !banner?.isDismissed,
					id: banner?.id,
				}
			} else if (banner?.name === 'Matching Banner') {
				result.matchingBanner = {
					isDismissed: !banner?.isDismissed,
					id: banner?.id,
				}
			} else if (banner?.name === 'Contact Recruiter Banner') {
				result.contactRecruiterBanner = {
					isDismissed: !banner?.isDismissed,
					id: banner?.id,
				}
			}
		})
	}
	return result
}

export function getIdsFromDismissedBanners(banners, condition) {
	const result = []
	if (banners?.welcomeBanner?.isDismissed === condition) {
		result.push(banners?.welcomeBanner?.id)
	}
	if (banners?.contactRecruiterBanner?.isDismissed === condition) {
		result.push(banners?.contactRecruiterBanner?.id)
	}
	return result
}

export const mappingTags = (tag_names) => {
	switch (tag_names) {
		case 'screening':
			return {
				title: 'Screening',
				status: 'start',
			}
		case 'job_submitted':
			return {
				title: 'Job Submitted',
				status: 'start',
			}
		case 'interview_scheduled':
			return {
				title: 'Scheduled Interview',
				status: 'pending',
			}
		case 'interview_accepted':
			return {
				title: 'Accepted Interview',
				status: 'pending',
			}
		case 'interview_cancelled':
			return {
				title: 'Cancelled Interview',
				status: 'fail',
			}
		case 'interview_rejected':
			return {
				title: 'Rejected Interview',
				status: 'fail',
			}
		case 'candidate_rescheduled':
			return {
				title: 'Rescheduled',
				status: 'pending',
			}
		case 'client_confirmed':
			return {
				title: 'Client Confirmed',
				status: 'pending',
			}
		case 'client_rejected':
			return {
				title: 'Client Rejected',
				status: 'fail',
			}
		case 'interview_failed':
			return {
				title: 'Interview Failed',
				status: 'fail',
			}
		case 'offer_sent':
			return {
				title: 'Offer Sent',
				status: 'pending',
			}
		case 'offer_rejected':
			return {
				title: 'Offer Rejected',
				status: 'fail',
			}
		case 'contract_signed':
			return {
				title: 'Contract Signed',
				status: 'success',
			}
		default:
			return {
				title: 'Screening',
				status: 'start',
			}
	}
}
export const mappingStatusColor = (status) => {
	switch (status) {
		case 'start':
			return '#1AA7EC'
		case 'pending':
			return '#00AAE5'
		case 'success':
			return '#309102'
		case 'fail':
			return '#FF2727'
		default:
			return '#1AA7EC'
	}
}

export const mappingStatusName = (status) => {
	if (!status) {
		return null
	}
	const words = status.split('_')

	// Capitalize the first letter of the first word
	const firstWordCapitalized = words[0].charAt(0).toUpperCase() + words[0].slice(1)

	// Join the first capitalized word with the rest of the words (uncapitalized)
	const result = [firstWordCapitalized, ...words.slice(1)].join(' ')

	return result
}

export const mappingStatusClassStyle = (status) => {
	switch (status) {
		case 'pending_start':
			return TAG_CLASS.pending
		case 'onboarding':
			return TAG_CLASS.start
		case 'not_confirmed':
			return TAG_CLASS.pending
		case 'confirmed':
			return TAG_CLASS.success
		case 'cancellation':
			return TAG_CLASS.cancel
		case 'completed':
			return TAG_CLASS.success
		case 'termination':
			return TAG_CLASS.cancel
		case 'approved':
			return TAG_CLASS.success
		case 'cleared':
			return TAG_CLASS.success
		case 'open':
			return TAG_CLASS.start
		case 'closed':
			return TAG_CLASS.cancel
		default:
			return TAG_CLASS.start
	}
}

export function getLastNameFirstName(str) {
	if (str) {
		const nameArray = str.split(' ') // Split the string into an array using the space as a separator
		return {
			firstName: nameArray[0],
			lastName: nameArray[1],
		}
	}
}

export function updateArray(dataBE, dataSelect) {
	if (isArray(dataBE) && isArray(dataSelect)) {
		const res = [...dataBE]

		dataSelect.forEach((state) => {
			const found = res.find((item) => item.preferredWorkingState === state)
			if (!found) {
				res.push({ preferredWorkingState: state })
			}
		})

		res.forEach((item) => {
			if (!dataSelect.includes(item.preferredWorkingState)) {
				item._destroy = true
			}
		})

		return res
	}
}

export function removeFalsyProps(obj) {
	// Create a new object to store the truthy properties
	const newObj = {}

	// Loop through each property in the original object
	for (const key in obj) {
		// Check if the property value is truthy or null/undefined
		if (obj[key] || obj[key] === '' || obj[key] === 0) {
			// Add the property to the new object
			newObj[key] = obj[key]
		} else if (obj[key] === null || obj[key] === undefined) {
			// Replace null/undefined values with empty string
			newObj[key] = ''
		}
	}

	// Return the new object with only truthy properties
	return newObj
}

export const handleCaculateProgress = (data) => {
	return Math.floor((data / 6) * 100) || 0
}

export const showMappingAddress = (data) => {
	const addressParts = []

	if (!!data?.city) {
		addressParts.push(data.city)
	}

	if (!!data?.state) {
		addressParts.push(data.state)
	}

	if (!!data?.zipcode) {
		addressParts.push(data.zipcode)
	}

	return addressParts.join(', ')
}

export const mappingItemPicklistDispline = (array, isDiscipline = false) => {
	return array?.map((item) => {
		if (isDiscipline) {
			const specialties = mappingItemPicklistSpecialty(item?.specialties)

			return {
				label: item?.name,
				value: item?.name,
				specialties,
			}
		}

		return {
			label: item?.name,
			value: item?.name,
		}
	})
}

export const mappingItemPicklistSpecialty = (array) => {
	return array?.map((item) => {
		return {
			...item,
			label: item?.name,
			value: item?.id,
		}
	})
}

export function getSaveSearchAnoName(array) {
	if (!array) {
		return
	}
	const pattern = 'My Saved Search'
	const numbers = array.map((item) => parseInt(item.name?.replace(pattern, ''), 10))

	const maxNumber = Math.max(...numbers)
	const newName = `${pattern} ${maxNumber + 1}`

	return newName
}
export function getStateValues(arr) {
	if (!isEmpty(arr)) {
		const statePattern = /(?:,\s*)?([A-Za-z\s]+)$/
		const stateValues = []

		arr.forEach((item) => {
			const match = item.match(statePattern)
			if (match && match[1]) {
				stateValues.push(match[1].trim())
			}
		})

		return stateValues
	}
}
export function removeNullOrUndefined(obj) {
	const newObj = {}
	for (let key in obj) {
		if (obj[key] !== null && obj[key] !== undefined) {
			newObj[key] = obj[key]
		}
	}
	return newObj
}
