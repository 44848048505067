import { action, store } from '~/common/mobx.decorator'
import { computed, observable } from 'mobx'
import { apolloClient, GET_WORKER_EARNED_BADGES_ICON_QUERY, GET_WORKER_ONBOARDING_INFO } from '~/common/apollo'
import { orderBy } from 'lodash'
import { careActivationStore } from '~/features/care-activation/care-activation.store'
import { PROFILE_STEP } from '~/common/constants'
import { ChecklistsTab, EduTab, InfoTab, ReferenceTab, ExternalReferenceTab, TermsTab, WorkTab } from '~/features/care-activation/tabs'
import { appStore } from '~/stores'

@store()
class OnboardingProgressStore {
	@observable completedPercent = 0
	@observable onboardingSteps = []
	@observable badges = []

	@computed
	get sortedOnboardingSteps() {
		return orderBy(this.onboardingSteps, 'stepInfo.stepOrder', 'asc')
	}

	@computed
	get profileStepId() {
		const profileStep = this.sortedOnboardingSteps?.find((step) => step.stepName === PROFILE_STEP.basic_info)
		return profileStep?.id
	}

	@computed
	get mappedTabComponents() {
		return this.sortedOnboardingSteps?.map((step) => {
			switch (step.stepName) {
				case PROFILE_STEP.basic_info:
					return {
						...step,
						tab: InfoTab,
					}
				case PROFILE_STEP.education:
					return {
						...step,
						tab: EduTab,
					}
				case PROFILE_STEP.work_experience:
					return {
						...step,
						tab: WorkTab,
					}
				case PROFILE_STEP.reference:
					return {
						...step,
						tab: appStore.isCrossmed ? ExternalReferenceTab : ReferenceTab,
					}
				case PROFILE_STEP.checklist:
					return {
						...step,
						tab: ChecklistsTab,
					}
				case PROFILE_STEP.term_sign:
					return {
						...step,
						tab: TermsTab,
					}
				default:
					return {
						...step,
						tab: InfoTab,
					}
			}
		})
	}

	@action
	fetchOnboardingOverview = async () => {
		const { data } = await apolloClient().query({
			query: GET_WORKER_ONBOARDING_INFO,
		})
		this.onboardingSteps = data?.workerOnboarding?.onboardingSteps ?? []
		this.completedPercent = data?.workerOnboarding?.completedPercent

		if (!careActivationStore.activeTab) {
			careActivationStore.changeActiveTab(this.sortedOnboardingSteps?.[0]?.id)
		}
	}

	@action
	fetchBadges = async () => {
		const response = await apolloClient().query({
			query: GET_WORKER_EARNED_BADGES_ICON_QUERY,
			variables: {
				first: 20,
			},
		})

		this.badges = response?.data?.workerBadges?.nodes ?? []
	}
}

export const onboardingProgressStore = new OnboardingProgressStore()
