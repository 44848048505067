import { Box, CircularProgress } from '@material-ui/core'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import React, { useState } from 'react'
import { CheckField } from '~/components/fields'
import { verficiationStyled } from '~/features/care-activation/components/verification-form'
import { termCheckBoxStyle } from './terms-tab.style'
import { Button } from '~/components/button'
import { Typography } from '~/components/typography'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { termsTabStore } from './terms-tab.store'
import { loadingState, observer } from '~/common/mobx.decorator'
import { useEffectOnce } from 'react-use'
import { CompleteOnboardingDialog } from './components/complete-onboarding-dialog.component'
import { logClickEvent, logTrackingScreenEvent } from '~/common/tracking/event-client.tracking'
import { TERM_SIGNED_TRACKING_EVENT } from '~/common/constants'
import HintBox from '../../components/hint-box'
import { useWindowScrollTop } from '~/common/hooks'
import { NotifyDialog } from '~/components/notify'
import { infoTabStore } from '../info/info-tab.store'
import { careActivationStore } from '../../care-activation.store'
import { onboardingProgressStore } from '~/components/onboarding-progress/onboarding-progress.store'
import { verifyEmailStore } from '~/components/verify-email/verify-email.store'

const validationSchema = yup.object().shape({
	term1: yup.boolean().oneOf([true], 'Please confirm the check box above.').required('Please confirm the check box above.'),
})

export const TermsTab = observer(({ onPreviousTab, hintText }) => {
	const { t } = useTranslation()
	const { content, submitTermSignedStep, fetchTermsService, acceptTerms, isOpen } = termsTabStore
	const loading = loadingState('termsTabStore/fetchTermsService')
	const loadingSubmit = loadingState('termsTabStore/submitTermSignedStep')
	const { emailVerified, phoneVerified } = infoTabStore
	const [showConfirmDialog, setShowConfirmDialog] = useState(false)

	useEffectOnce(() => {
		logTrackingScreenEvent(TERM_SIGNED_TRACKING_EVENT.SCREEN_TRACKING, { is_start_screen_session: true })
		fetchTermsService()
	})

	useWindowScrollTop()

	const handleSubmitTerm = (e) => {
		logClickEvent(TERM_SIGNED_TRACKING_EVENT.CLICK_NEXT_STEP_BTN, {})
		submitTermSignedStep(setShowConfirmDialog)
	}
	return (
		<Box css={verficiationStyled} m={2}>
			<HintBox text={hintText} />
			{loading ? (
				<Box display="flex" justifyContent="center">
					<CircularProgress />
				</Box>
			) : (
				<>
					<Typography variant="h3">{t('CERTIFY_APPLICATION')}</Typography>
					<span dangerouslySetInnerHTML={{ __html: content }} />

					<Formik initialValues={{ term1: false }} onSubmit={handleSubmitTerm} validationSchema={validationSchema}>
						{({ handleSubmit, isSubmitting }) => {
							return (
								<Form onSubmit={handleSubmit}>
									<Box display="flex" flexDirection="column" css={termCheckBoxStyle} className="terms-of-service-checkbox" mt={2}>
										{acceptTerms.map((term, index) => (
											<CheckField
												key={index}
												name={`term${index + 1}`}
												label={term}
												onClick={() => {
													logClickEvent(TERM_SIGNED_TRACKING_EVENT.CLICK_CHECKBOX_PROFILE, {})
												}}
											/>
										))}
									</Box>

									<Box display="flex" gridGap="10px" mt={3}>
										<Button
											fullWidth={true}
											variant="outlined"
											color="primary"
											onClick={() => {
												logClickEvent(TERM_SIGNED_TRACKING_EVENT.CLICK_PREVIOUS_BTN, {})
												onPreviousTab()
											}}
										>
											<Typography variant="title1">{t('PREVIOUS_STEP')}</Typography>
										</Button>

										<Button loading={loadingSubmit} fullWidth={true} variant="contained" color="primary" type="submit">
											<Typography variant="title1">{t('COMPLETE')}</Typography>
										</Button>
									</Box>
								</Form>
							)
						}}
					</Formik>
				</>
			)}
			{isOpen && <CompleteOnboardingDialog open={true} />}

			<NotifyDialog
				open={showConfirmDialog}
				title="To finish setting up your profile, please verify either your email address or phone number"
				showButtonClose={false}
				hideActions={true}
				isShowOkText={false}
			>
				<Box display="flex" flexDirection="column" gridGap={2}>
					<Typography color="textSecondary">This ensures secure communication and account access.</Typography>

					<Box display="flex" flexDirection="column" gridGap={1}>
						{!emailVerified && (
							<Button
								fullWidth
								color="primary"
								onClick={async () => {
									infoTabStore.toggleShowVerifyPhoneModal(false)
									await careActivationStore.changeActiveTab(onboardingProgressStore.profileStepId)
									await verifyEmailStore.setEmail(infoTabStore.worker?.email)
									await verifyEmailStore.toggleShowVerifyEmail(true)
								}}
								style={{ marginTop: 10 }}
							>
								Verify email
							</Button>
						)}

						{!phoneVerified && (
							<Button
								fullWidth
								color="primary"
								onClick={async () => {
									await verifyEmailStore.toggleShowVerifyEmail(false)
									await careActivationStore.changeActiveTab(onboardingProgressStore.profileStepId)
									infoTabStore.toggleShowVerifyPhoneModal(true)
								}}
								style={{ marginTop: 10 }}
							>
								Verify phone number
							</Button>
						)}

						<Button
							fullWidth
							variant="outlined"
							onClick={() => {
								setShowConfirmDialog(false)
							}}
							style={{ marginTop: 10 }}
						>
							{t('DO_IT_LATER')}
						</Button>
					</Box>
				</Box>
			</NotifyDialog>
		</Box>
	)
})
