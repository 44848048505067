import { Box, CircularProgress, Collapse, FormHelperText, SvgIcon } from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import React, { useCallback, useMemo, useState } from 'react'
import { EVENTS, FIELD_MODE } from '~/common/constants'
import { useFieldError } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { Button } from '~/components/button'
import { Typography } from '~/components/typography'
import {
	filenameStyle,
	headingStyle,
	hintStyle,
	imageBoxStyle,
	inputStyle,
	itemBoxStyle,
	labelStyle,
	reviewUploadStyled,
	uploadButtonStyle,
	uploadInputStyled,
	wrapperStyle,
} from './file-upload-field.style'
import { blobToFile, captureException, getFileExtension, removeFile as defaultRemoveFile, uploadFile as defaultUploadFile } from '~/common/helpers'
import { filter, findIndex, startsWith } from 'lodash'
import { AddImageSvg, DocSvg, PdfSvg } from '~/components/icons'
import { useDebounce } from 'react-use'
import { eventBus } from 'mobx-event-bus2'
import UploadListImage from '~/components/fields/file-upload-field/upload-list-image'

import { ImagePreviewCarousel } from '~/components/image-preview-carousel'
import UploadListDoc from '~/components/fields/file-upload-field/upload-list-doc'
import UploadListPdf from '~/components/fields/file-upload-field/upload-list-pdf'
import { HEIC_TYPES, SPECIAL_TYPES } from './file-upload.helper'
import heic2any from 'heic2any'
import { nanoid } from 'nanoid'

const ICONS = {
	'application/doc': DocSvg,
	'application/msword': DocSvg,
	'application/ms-doc': DocSvg,
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': DocSvg,
	'application/pdf': PdfSvg,
	'application/xml': DocSvg,
	'image/png': DocSvg,
	'image/jpeg': DocSvg,
	'image/pdf': DocSvg,
	'image/heic': DocSvg,
	'image/gif': DocSvg,
	'application/vnd.ms-excel': DocSvg,
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': DocSvg,
	'text/plain': DocSvg,
	'application/vnd.ms-outlook': DocSvg,
}

const IMAGE_TYPE = 'image/'

export const FileUploadField = ({
	name,
	label,
	validate,
	mode,
	multiple,
	camera,
	uploadFile,
	removeFile,
	accept,
	hint,
	title,
	subTitle,
	handleUploaded,
	hideDelete = false,
	hideUploadedStatus = false,
	invisibleUploadInput = false,
	onPreviewTracking,
	callBackRemove,
	callbackChange,
	isAllowShowCtaDownload = false,
	onClickDownload,
	heading,
}) => {
	const { t } = useTranslation()
	const { setFieldValue } = useFormikContext()
	const [showPreview, setShowPreview] = useState()
	const [processingItems, setProcesingItems] = useState([])

	const validateFunc = useCallback(
		(value) => {
			if (processingItems?.length > 0) {
				return 'UPLOADING'
			}

			if (typeof validate === 'function') {
				return validate(value)
			}
		},
		[validate, processingItems]
	)

	const [field, meta] = useField({ name, validate: validateFunc })

	const id = useMemo(() => `${name}_file_upload`, [name])

	const error = useFieldError(meta)
	const [activeImage, setActiveImage] = useState({})
	useDebounce(
		async () => {
			if (processingItems.length === 0) {
				return
			}

			const [file, ...rest] = processingItems

			try {
				if (HEIC_TYPES.some((t) => file?.type === t) || SPECIAL_TYPES.some((t) => file?.name?.includes(t))) {
					await heic2any({ blob: file, toType: 'image/jpeg', quality: 1 }).then(async (newImage) => {
						const re = /HEIC|HEIF/gi
						const newFile = await blobToFile(newImage, file?.name?.replace(re, 'jpeg'))

						const { signedBlobId, preSignedUrl, blobId } = await uploadFile(newFile)

						const values = [{ filename: newFile.name, contentType: newFile.type, signedBlobId, src: preSignedUrl, blobId }, ...(field.value || [])]
						field.onChange({ target: { name, value: values } })
					})
				} else {
					const res = await uploadFile(file)
					const { signedBlobId, preSignedUrl, blobId } = res

					const values = [
						...(field.value || []),
						{
							filename: file.name,
							contentType: file.type,
							signedBlobId,
							fileUrl: preSignedUrl,
							blobId: blobId,
						},
					]
					await field.onChange({ target: { name, value: values } })
					await setProcesingItems(rest)
					handleUploaded && (await handleUploaded(values))
				}
			} catch (error) {
				captureException('File upload failed.', error)
				eventBus.post(EVENTS.notifyStore.fireError, { message: `File upload failed. "${file.name}"` })
			} finally {
				await setProcesingItems(rest)
			}
		},
		300,
		[processingItems]
	)

	const handleChange = useCallback(
		async (event) => {
			const files = Array.from(event?.target?.files)
			event.target.value = ''

			const invalidFileSize = files?.some((file) => file.size >= 10 * 1000 * 1000)
			const invalidFileType = files?.some(
				(file) => !(Object.keys(ICONS).includes(file?.type) || startsWith(file.type, IMAGE_TYPE) || getFileExtension(file.name) === 'msg')
			)

			if (invalidFileSize) {
				return eventBus.post(EVENTS.notifyStore.fireError, { message: '$ERRORS.MAXIMUM_FILE_SIZE' })
			}

			if (invalidFileType) {
				return eventBus.post(EVENTS.notifyStore.fireError, { message: '$ERRORS.UNSUPPORT_CONTENT_TYPE' })
			}

			if (invisibleUploadInput) {
				const deleteDocumentButton = document.querySelector('#resumes_file_removed')

				if (deleteDocumentButton) {
					deleteDocumentButton.click()
				}
			}
			callbackChange && callbackChange(files)

			setProcesingItems(files)
		},
		// eslint-disable-next-line
		[invisibleUploadInput]
	)

	const handleDelete = useCallback(
		async (fileUrl) => {
			const index = findIndex(field?.value, (file) => file.fileUrl === fileUrl)
			const file = field?.value?.[index]
			if (file.id) {
				setFieldValue(`${name}.${index}._destroy`, true)
				setTimeout(() => removeFile(file.id), 100)
			}

			if (callBackRemove) {
				callBackRemove(file)
			}
			setFieldValue(`${name}.${index}._destroy`, true)
		},
		[setFieldValue, name, removeFile, field.value, callBackRemove]
	)

	const visible = useMemo(() => field?.value?.filter((item) => !item._destroy)?.length > 0 || processingItems.length > 0, [field.value, processingItems])

	const disabled = useMemo(() => processingItems?.length > 0, [processingItems])

	const actualViewValue = filter(field?.value, (v) => !v._destroy)

	const hideUpload = useMemo(() => !multiple && (processingItems?.length > 0 || actualViewValue.length > 0), [processingItems, multiple, actualViewValue])

	const handlePreviewImage = (item) => {
		setActiveImage({
			url: item?.imageUrls?.s_500x500 || item?.src || item?.fileUrl,
			title: item.filename,
		})
		setShowPreview(true)
	}

	const handlePreviewPdf = async (item) => {
		await window.open(item?.fileUrl || item?.src, '_blank')
	}

	const handleSelectImgList = (item) => {
		setActiveImage({
			url: item?.src || item?.fileUrl,
			title: item?.alt,
		})
	}
	const listImageSlider = field.value
		?.filter((item) => !item.hasOwnProperty('_destroy'))
		.map((item) => {
			return {
				alt: item?.filename,
				src: item?.imageUrls?.s_500x500 || item?.src || item?.fileUrl,
				id: item?.id,
				contentType: item?.contentType,
			}
		})
	return (
		<>
			{label && <Typography css={labelStyle}>{t(label)}</Typography>}
			{hint && <Typography css={hintStyle}>{t(hint)}</Typography>}
			{heading && (
				<Box mb={1}>
					<Typography css={headingStyle}>{t(heading)}</Typography>
				</Box>
			)}
			<Box display="flex" flexDirection="column" gridGap={16}>
				<Box css={reviewUploadStyled}>
					{(!actualViewValue || actualViewValue.length === 0) && label && mode === FIELD_MODE.view && (
						<Box mt={1} mb={1}>
							{t('$PLACEHOLDERS.NONE')}
						</Box>
					)}
					<Collapse className="preview-image" in={visible}>
						<Box>
							{field.value
								?.filter((item) => !(item._destroy === true))
								?.map(
									(item, index) =>
										!item?._destroy &&
										(item?.contentType === 'application/pdf' ? (
											<UploadListPdf
												isAllowShowCtaDownload={isAllowShowCtaDownload}
												invisibleUploadInput={invisibleUploadInput}
												hideUploadedStatus={hideUploadedStatus}
												hideDelete={hideDelete}
												key={nanoid()}
												onClick={() => handlePreviewPdf(item)}
												item={item}
												mode={mode}
												hasBorder={true}
												onClick1={(e) => {
													e.stopPropagation()
													handleDelete(item.fileUrl)
												}}
												onClickDownload={onClickDownload}
											/>
										) : startsWith(item?.contentType, IMAGE_TYPE) ? (
											<UploadListImage
												isAllowShowCtaDownload={isAllowShowCtaDownload}
												invisibleUploadInput={invisibleUploadInput}
												hideUploadedStatus={hideUploadedStatus}
												hideDelete={hideDelete}
												key={index}
												onClick={() => {
													if (onPreviewTracking) onPreviewTracking()
													handlePreviewImage(item)
												}}
												item={item}
												mode={mode}
												hasBorder={true}
												className="image"
												onClick1={(e) => {
													e.stopPropagation()
													handleDelete(item.fileUrl)
												}}
											/>
										) : (
											<UploadListDoc
												isAllowShowCtaDownload={isAllowShowCtaDownload}
												invisibleUploadInput={invisibleUploadInput}
												hideUploadedStatus={hideUploadedStatus}
												hideDelete={hideDelete}
												key={index}
												onClick={() => handlePreviewPdf(item)}
												item={item}
												mode={mode}
												hasBorder={true}
												onClick1={(e) => {
													e.stopPropagation()
													handleDelete(item.fileUrl)
												}}
												onClickDownload={onClickDownload}
											/>
										))
								)}

							{processingItems?.map((item, index) => (
								<div key={index}>
									<Box css={[...itemBoxStyle, { display: 'flex', justifyContent: 'center', alignItems: 'center' }]} key={`${item.name}_${index}`}>
										<Box className="preview-icon">
											<CircularProgress size={18} />
										</Box>
										<Typography css={[...filenameStyle, { marginLeft: '10px' }]}>Loading ...</Typography>
									</Box>
								</div>
							))}
							<ImagePreviewCarousel
								className="image-preview-carousel"
								handlePreviewPdf={handlePreviewPdf}
								handleSelectImgList={handleSelectImgList}
								listImageSlider={listImageSlider}
								open={showPreview}
								activeImage={activeImage}
								onClose={() => setShowPreview(false)}
							/>
						</Box>
					</Collapse>
				</Box>

				{mode === FIELD_MODE.edit && !hideUpload && (
					<Box css={uploadInputStyled} position="relative">
						<input
							disabled={disabled}
							accept={accept}
							max={3}
							multiple={multiple}
							camera={camera}
							id={id}
							type="file"
							css={inputStyle}
							onChange={handleChange}
						/>

						<label htmlFor={id} css={wrapperStyle}>
							<Button border={2} variant="outlined" component="span" css={uploadButtonStyle}>
								<Box className="content"></Box>
							</Button>

							<Box pl={2} pr={2} css={imageBoxStyle}>
								<div className="container">
									<div className="icon">
										<SvgIcon component={AddImageSvg} />
									</div>
									<Box>
										<div className="upload-comp-title">{t(title)}</div>
										<div className="upload-comp-sub-title">{t(subTitle)}</div>
									</Box>
								</div>
							</Box>
						</label>
					</Box>
				)}
			</Box>

			{mode === FIELD_MODE.edit && invisibleUploadInput && (
				<Box display="none">
					<input disabled={disabled} accept={accept} max={3} multiple={multiple} camera={camera} id={id} type="file" css={inputStyle} onChange={handleChange} />
				</Box>
			)}

			{error && (
				<FormHelperText error={true} style={{ position: 'relative', top: 0 }}>
					{error}
				</FormHelperText>
			)}
		</>
	)
}

FileUploadField.defaultProps = {
	mode: FIELD_MODE.edit,
	camera: true,
	removeFile: defaultRemoveFile,
	uploadFile: defaultUploadFile,
	accept: '.png,.jpg,.jpeg,.pdf,.doc,.docx,.heic',
	multiple: true,
}
