import React, { useMemo } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { FormMode } from '@opus/web.core.form.utils'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { useFormikContext } from 'formik'
import { AutoCompleteField, DateField, TextField, YesNoField } from '~/components/fields'
import { get, isEmpty } from 'lodash'
import { masterStore } from '~/stores'
import { observer } from '~/common/mobx.decorator'
import { labelEndStyle } from '~/features/care-profile/care-profile.style'
import { CityField } from '~/components/fields/city-field/city-field.component'

export const ExperienceForm = observer(({ mode = FormMode.Edit, singleColumn, prefixName, formValues }) => {
	const { t } = useTranslation()
	const { unitType, workLocations, reasonsForLeaving } = masterStore
	const { setFieldValue, values } = useFormikContext()

	const currentlyEmployed = useMemo(() => get(values, prefixName ? `${prefixName}.currentlyEmployed` : 'currentlyEmployed') || false, [values, prefixName])
	const gapInEmployment = useMemo(() => get(values, prefixName ? `${prefixName}.gapInEmployment` : 'gapInEmployment') || false, [values, prefixName])
	const otherReasonLeaving = useMemo(
		() => get(values, prefixName ? `${prefixName}.reasonForLeaving` : 'reasonForLeaving') === 'Other (requires explanation)' || false,
		[values, prefixName]
	)

	const nameFieldState = prefixName ? `${prefixName}.state` : 'state'
	const hasCities = !isEmpty(get(values, nameFieldState)) ? workLocations.find((state) => state.value === get(values, nameFieldState))?.hasCities : true

	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12} md={12}>
					<TextField
						name={prefixName ? `${prefixName}.facilityName` : 'facilityName'}
						label={t('FACILITY_NAME')}
						placeholder={t('$PLACEHOLDERS.ENTER_FACILITY_NAME')}
						mode={mode}
					/>
				</Grid>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<AutoCompleteField
						options={unitType}
						groupBy={(option) => option.groupLabel}
						name={prefixName ? `${prefixName}.unitType` : 'unitType'}
						placeholder={t('$PLACEHOLDERS.SELECT_UNIT_TYPE')}
						label={t('UNIT_TYPE')}
						mode={mode}
						excludedOptionLabel="undefined"
						disableGroups={unitType.filter((item) => item.label?.toLowerCase() !== 'undefined').map((item) => item.groupLabel)}
						allowCustomValue
					/>
				</Grid>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<TextField
						name={prefixName ? `${prefixName}.title` : 'title'}
						placeholder={t('$PLACEHOLDERS.POSITION_TITLE')}
						label={t('POSITION_TITLE')}
						mode={mode}
					/>
				</Grid>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<AutoCompleteField
						handleWithCurrentValue={(currentValue) => {
							if (!!currentValue) {
								setFieldValue(prefixName ? `${prefixName}.city` : 'city', undefined)
							}
						}}
						multiple={false}
						name={prefixName ? `${prefixName}.state` : 'state'}
						label={t('STATE')}
						placeholder={t('$PLACEHOLDERS.STATE')}
						mode={mode}
						options={workLocations}
						allowCustomValue
					/>
				</Grid>

				{hasCities && (
					<Grid item xs={12} md={singleColumn ? 12 : 6}>
						<CityField
							showStateCode={false}
							id={'city'}
							name={prefixName ? `${prefixName}.city` : 'city'}
							label={t('CITY')}
							placeholder="$PLACEHOLDERS.CITY"
							mode={mode}
							state={prefixName ? `${prefixName}.state` : 'state'}
						/>
					</Grid>
				)}

				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<YesNoField name={prefixName ? `${prefixName}.workedThroughStaffingFirm` : 'workedThroughStaffingFirm'} label={'WORK_STAFFING'} mode={mode} />
				</Grid>
				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<TextField
						name={prefixName ? `${prefixName}.agency` : 'agency'}
						placeholder={t('$PLACEHOLDERS.TYPE_AGENCY')}
						label={t('AGENCY_USED')}
						mode={mode}
						endLabel={
							<Typography variant="subtitle2" css={labelEndStyle}>
								{t('OPTIONAL')}
							</Typography>
						}
					/>
				</Grid>

				<Grid item xs={12} md={12}>
					<YesNoField name={prefixName ? `${prefixName}.gapInEmployment` : 'gapInEmployment'} label={'GAP_EMPLOYMENT'} mode={mode} />
				</Grid>
				{gapInEmployment && (
					<>
						<Grid item xs={12} md={12}>
							<TextField
								name={prefixName ? `${prefixName}.gapReason` : 'gapReason'}
								placeholder={t('$PLACEHOLDERS.GAP_REASON')}
								label={t('GAP_REASON')}
								mode={mode}
							/>
						</Grid>

						<Grid item xs={12} md={singleColumn ? 12 : 6}>
							<DateField
								maxDate={new Date()}
								mode={mode}
								placeholder="$PLACEHOLDERS.GAP_START_DATE"
								name={prefixName ? `${prefixName}.gapStartDate` : 'gapStartDate'}
								label="GAP_START_DATE"
								disableTyping={true}
							/>
						</Grid>

						<Grid item xs={12} md={singleColumn ? 12 : 6}>
							<DateField
								maxDate={new Date()}
								placeholder="$PLACEHOLDERS.GAP_END_DATE"
								mode={mode}
								name={prefixName ? `${prefixName}.gapEndDate` : 'gapEndDate'}
								label="GAP_END_DATE"
								disableTyping={true}
							/>
						</Grid>
					</>
				)}

				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<YesNoField
						name={prefixName ? `${prefixName}.currentlyEmployed` : 'currentlyEmployed'}
						label={'CURRENT_EMPLOYMENT'}
						mode={mode}
						endLabel={
							gapInEmployment && (
								<Typography variant="subtitle2" css={labelEndStyle}>
									{t('OPTIONAL')}
								</Typography>
							)
						}
					/>
				</Grid>
				{!singleColumn && <Grid item xs={12} md={6}></Grid>}

				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					<DateField
						maxDate={new Date()}
						mode={mode}
						placeholder="$PLACEHOLDERS.SELECT_START_DATE"
						name={prefixName ? `${prefixName}.startDate` : 'startDate'}
						label="START_DATE"
						disableTyping={true}
						endLabel={
							gapInEmployment && (
								<Typography variant="subtitle2" css={labelEndStyle}>
									{t('OPTIONAL')}
								</Typography>
							)
						}
					/>
				</Grid>

				<Grid item xs={12} md={singleColumn ? 12 : 6}>
					{!currentlyEmployed && (
						<DateField
							maxDate={new Date()}
							placeholder="$PLACEHOLDERS.SELECT_END_DATE"
							disabled={values?.currentlyEmployed}
							mode={mode}
							name={prefixName ? `${prefixName}.endDate` : 'endDate'}
							label="END_DATE"
							disableTyping={true}
							endLabel={
								gapInEmployment && (
									<Typography variant="subtitle2" css={labelEndStyle}>
										{t('OPTIONAL')}
									</Typography>
								)
							}
						/>
					)}
				</Grid>
				{!currentlyEmployed && (
					<Grid item xs={12} md={12}>
						<AutoCompleteField
							options={reasonsForLeaving}
							name={prefixName ? `${prefixName}.reasonForLeaving` : 'reasonForLeaving'}
							placeholder={t('$PLACEHOLDERS.REASON_LEAVING')}
							label={t('REASON_LEAVING')}
							mode={mode}
							allowCustomValue
							endLabel={
								<Typography variant="subtitle2" css={labelEndStyle}>
									{t('OPTIONAL')}
								</Typography>
							}
						/>
					</Grid>
				)}

				{!currentlyEmployed && otherReasonLeaving && (
					<Grid item xs={12} md={12}>
						<TextField
							name={prefixName ? `${prefixName}.reasonForLeavingNote` : 'reasonForLeavingNote'}
							label={t('COMMENTS')}
							placeholder={t('$PLACEHOLDERS.COMMENTS')}
							mode={mode}
							endLabel={
								<Typography variant="subtitle2" css={labelEndStyle}>
									{t('OPTIONAL')}
								</Typography>
							}
						/>
					</Grid>
				)}
			</Grid>
		</>
	)
})
