import { action, computed, observable, store } from '~/common/mobx.decorator'
import { filter, find, flatMap, map } from 'lodash'
import {
	apolloClient,
	GET_NUMBER_COMPLETE_STEPS,
	GET_WORKER_ONBOARDING_OVERVIEW_QUERY,
	GET_WORKER_RESUMES_QUERY,
	UPDATE_WORKER_ONBOARDING_PART_MUTATION,
	UPDATE_WORKER_RESUMES_MUTATION,
} from '~/common/apollo'
import { authStore, fireStore, masterStore, notifyStore, routerStore } from '~/stores'
import { COLECTIONS, PATHS } from '~/common/constants'
import { INTEGRATION_OBJECTS } from './utils/constants'
import { CONVERT_PART_TO_FORM_DATA } from './utils/convert-part-to-form-data'
import { CONVERT_FORM_TO_SAVE_DATA } from './utils/convert-form-to-save-data'
import { eventClient, events } from '@opus/web.core.lib.event-tracking'
import { handleCaculateProgress } from '~/common/helpers/mapping.helper'
import { mutateWithTrimmedVariables } from '~/common/helpers'

const EVENTS = {
	[INTEGRATION_OBJECTS.profile]: new events.OnboardingClickNextStepSuccessEvent({ step_id: 0 }),
	[INTEGRATION_OBJECTS.educations]: new events.OnboardingClickNextStepSuccessEvent({ step_id: 1 }),
	[INTEGRATION_OBJECTS.experiences]: new events.OnboardingClickNextStepSuccessEvent({ step_id: 2 }),
	[INTEGRATION_OBJECTS.references]: new events.OnboardingClickNextStepSuccessEvent({ step_id: 3 }),
	[INTEGRATION_OBJECTS.licenses]: new events.OnboardingClickNextStepSuccessEvent({ step_id: 4 }),
	[INTEGRATION_OBJECTS.terms]: new events.OnboardingClickCompleteApplicationSuccessEvent({ step_id: 5 }),
}
@store()
class CareActivationStore {
	@observable activeTab = ''
	@observable prevActiveTab = -1
	@observable worker = {}
	@observable contacts = []
	@observable submitPersonalInfo = {}

	@observable prevStepIndex = -1
	@observable stepIndex = -1
	@observable stepData = {}
	@observable dirtyData = {}
	@observable percentPerStep = {}
	@observable resumes = []
	@observable workerOnboardingOverview
	@observable isShowDialogSuccessOnboarding = false

	@observable firebaseData
	@observable hasLastJob = false
	@observable lastJobIsAvailble = false
	@observable lastJobId = null
	@observable preferState = []
	@observable numberCompleteStep = 0

	@observable groupedSpecialties = {}
	@observable primarySpecialty = {}

	@computed
	get totalPercent() {
		return handleCaculateProgress(this.numberCompleteStep)
	}

	@computed
	get slideDirection() {
		return this.stepIndex > this.prevStepIndex ? 'left' : 'right'
	}

	@computed
	get steps() {
		return [
			{
				id: INTEGRATION_OBJECTS.profile,
				name: 'PROFILE_INFORMATION',
				description: this.workerOnboardingOverview?.onboardingParts?.find(
					(part) => part?.workerOnboardingPart?.integrationObject === INTEGRATION_OBJECTS.profile
				)?.description,
				incompleted: '$MESSAGES.PROFILE_INCOMPLETED_DATA',
				eventId: 'personal-info-application_btn',
				eventIdHorizon: 'step-1_btn',
			},
			{
				id: INTEGRATION_OBJECTS.educations,
				name: 'EDUCATION_HISTORY',
				description: this.workerOnboardingOverview?.onboardingParts?.find(
					(part) => part?.workerOnboardingPart?.integrationObject === INTEGRATION_OBJECTS.educations
				)?.description,
				incompleted: '$MESSAGES.EDUCATION_INCOMPLETED_DATA',
				eventId: 'education-application_btn',
				eventIdHorizon: 'step-2_btn',
			},
			{
				id: INTEGRATION_OBJECTS.experiences,
				name: 'WORK_EXPERIENCE',
				description: this.workerOnboardingOverview?.onboardingParts?.find(
					(part) => part?.workerOnboardingPart?.integrationObject === INTEGRATION_OBJECTS.experiences
				)?.description,
				incompleted: '$MESSAGES.EXPERIENCE_INCOMPLETED_DATA',
				eventId: 'work-experience-application_btn',
				eventIdHorizon: 'step-3_btn',
			},
			{
				id: INTEGRATION_OBJECTS.references,
				name: 'REFERENCES',
				description: this.workerOnboardingOverview?.onboardingParts?.find(
					(part) => part?.workerOnboardingPart?.integrationObject === INTEGRATION_OBJECTS.references
				)?.description,
				incompleted: '$MESSAGES.REFERENCE_INCOMPLETED_DATA',
				eventId: 'reference-application_btn',
				eventIdHorizon: 'step-4_btn',
			},

			{
				id: INTEGRATION_OBJECTS.skillChecklist,
				name: 'SKILL_CHECKLISTS',
				description: this.workerOnboardingOverview?.onboardingParts?.find(
					(part) => part?.workerOnboardingPart?.integrationObject === INTEGRATION_OBJECTS.skillChecklist
				)?.description,
				incompleted: '$MESSAGES.LICENSE_INCOMPLETED_DATA',
				eventId: 'licenses-certs-application_btn',
				eventIdHorizon: 'step-5_btn',
			},
			{
				id: INTEGRATION_OBJECTS.terms,
				name: 'TERMS_AND_ELIGIBILITY',
				description: this.workerOnboardingOverview?.onboardingParts?.find((part) => part?.workerOnboardingPart?.integrationObject === INTEGRATION_OBJECTS.terms)
					?.description,
				incompleted: '$MESSAGES.TERMS_INCOMPLETED_DATA',
				eventId: 'terms-eligibility-application_btn',
				eventIdHorizon: 'step-6_btn',
			},
		]
	}

	@computed
	get descriptionStepsData() {
		return this.workerOnboardingOverview?.companyOnboarding?.description || ''
	}

	@computed
	get activeStep() {
		return this.steps?.[this.stepIndex]
	}

	@computed
	get incompletedStepIndex() {
		return this.steps.findIndex((step) => this.percentPerStep[step.id] !== 100)
	}

	@computed
	get title() {
		return this.activeStep?.name || 'COMPLETE_APPLICATION'
	}

	@computed
	get description() {
		return this.activeStep?.description
	}

	@computed
	get disciplineOptions() {
		return masterStore.disciplines
	}

	@computed
	get licenseStatesOptions() {
		return masterStore.licenseStates
	}

	@computed
	get enableReinitialize() {
		return !!this.firebaseData
	}

	@computed
	get unitOptions() {
		const options = find(masterStore.disciplines, { value: 'RN' })
		return options?.dependent_values
	}

	@computed
	get chartingOptions() {
		return masterStore.chartings
	}

	@computed
	get agencyOptions() {
		return masterStore.agencies
	}

	@computed
	get primarySpecialityOptions() {
		return flatMap(masterStore.disciplines?.map(({ dependent_values = [], value }) => dependent_values.map((dep) => ({ ...dep, parentValue: value }))))
	}

	@computed
	get secondarySpecialityOptions() {
		return masterStore.secondarySpecialities
	}

	@computed
	get facilityOptions() {
		const experiences = this.stepData?.[INTEGRATION_OBJECTS.experiences]?.workExperiences

		return map(experiences, ({ facilityName }) => ({ value: facilityName, label: facilityName }))
	}

	@action
	checkLastJobAvailble = async () => {
		const doc = await fireStore.db.collection(COLECTIONS.last_job_id).doc(authStore.id).get()
		const data = doc.data()

		if (data?.jobId) {
			this.lastJobId = data.jobId
			this.hasLastJob = true
		}

		// const response = await apolloClient().query({ query: GET_JOB_DETAIL_QUERY, variables: { id: data?.jobId } })

		// if (response.data?.job?.id) {
		// 	this.lastJobIsAvailble = true
		// }
	}

	@action
	changeActiveTab = async (tabValue, isExperienceTab) => {
		if (this.activeTab === tabValue) {
			return
		}

		this.prevActiveTabIndex = -1
		this.activeTab = tabValue
	}

	@action
	onClickBack = () => {
		if (this.stepIndex > -1 && this.stepIndex < 6) {
			this.changeActiveStep(this.stepIndex - 1)
		}
	}
	@action
	setIsShowDialogSuccessOnboarding = (value) => {
		this.isShowDialogSuccessOnboarding = value
	}

	@action
	changeActiveStep = async (stepIndex, force = false) => {
		if (!force && (this.stepIndex > 5 || stepIndex > 6 || stepIndex < -1 || this.stepIndex === stepIndex)) {
			return
		}

		this.prevStepIndex = this.stepIndex
		this.stepIndex = stepIndex

		//Get firebase data for new step
		const step = this.steps[stepIndex]
		const formData = this.stepData[step?.id]

		if (formData) {
			const docRef = fireStore.db.collection(COLECTIONS.activations).doc(formData?.__workerOnboardingPart?.id)
			const firebaseData = (await docRef.get()).data()
			this.firebaseData = firebaseData
		}
	}

	@action
	changeActiveStepAndSubmit = async (stepIndex) => {
		const step = this.steps[this.stepIndex]
		const percent = this.percentPerStep[step?.id]
		const formData = this.stepData[step?.id]
		const dirty = this.dirtyData[step?.id]

		this.changeActiveStep(stepIndex)

		// Save Or Submit current step
		if (formData) {
			const docRef = fireStore.db.collection(COLECTIONS.activations).doc(formData?.__workerOnboardingPart?.id)

			if (percent === 100 && step.id !== INTEGRATION_OBJECTS.terms) {
				await docRef.delete()
			} else if (percent > 0 && dirty) {
				await docRef.set(formData)
			}
		}
	}

	@action
	handleContinueFirebaseData = () => {
		const formData = this.firebaseData

		if (this.activeStep.id === INTEGRATION_OBJECTS.experiences || this.activeStep.id === INTEGRATION_OBJECTS.educations) {
			formData.__hasResumes = this.resumes?.filter((resume) => !resume?._destroy).length > 0
		}

		this.stepData[this.activeStep.id] = formData

		setTimeout(() => {
			this.firebaseData = null
		}, 500)
	}

	@action
	handleCloseFirebaseData = async () => {
		const docRef = fireStore.db.collection(COLECTIONS.activations).doc(this.firebaseData?.__workerOnboardingPart?.id)
		await docRef.delete()
		await this.refreshStepData(this.workerOnboardingOverview, this.firebaseData?.__workerOnboardingPart?.integrationObject)
		this.firebaseData = null
	}

	@action
	moveNextStep = async () => {
		if (this.stepIndex === 5 && this.incompletedStepIndex !== -1) {
			await this.changeActiveStep(this.incompletedStepIndex)
		} else {
			if (this.stepIndex === 5) {
				await routerStore.goPage(PATHS.care.home)
				await this.setIsShowDialogSuccessOnboarding(true)
			}
			await this.changeActiveStep(this.stepIndex + 1)
		}
	}

	@action
	moveBackStep = () => {
		this.changeActiveStep(this.stepIndex - 1)
	}

	@action
	fetchNumberCompleteStepOnboarding = async () => {
		const { data } = await apolloClient().mutate({ mutation: GET_NUMBER_COMPLETE_STEPS })
		this.numberCompleteStep = data?.workerOnboardingOverview?.numberOfCompletedSteps
	}

	@action
	fetchOnboardingOverview = async () => {
		const { data } = await apolloClient().mutate({ mutation: GET_WORKER_ONBOARDING_OVERVIEW_QUERY })

		this.workerOnboardingOverview = data.workerOnboardingOverview

		this.preferState =
			this.workerOnboardingOverview?.onboardingParts?.find((part) => part?.workerOnboardingPart?.integrationObject === INTEGRATION_OBJECTS.profile)
				?.workerOnboardingPart?.integration?.worker?.workingPreferredLocations || []
		await this.refreshStepData(this.workerOnboardingOverview)
	}

	@action
	refreshStepData = async (workerOnboardingOverview, refreshKey) => {
		Object.values(INTEGRATION_OBJECTS).forEach(async (key) => {
			if (refreshKey && refreshKey !== key) {
				return
			}

			const part = workerOnboardingOverview?.onboardingParts?.find((part) => part?.workerOnboardingPart?.integrationObject === key)

			const converter = CONVERT_PART_TO_FORM_DATA[key]
			const formData = converter(part?.workerOnboardingPart || {})

			if (key === INTEGRATION_OBJECTS.educations || key === INTEGRATION_OBJECTS.experiences) {
				formData.__hasResumes = this.resumes?.filter((resume) => !resume?._destroy).length > 0
			}
			this.stepData[key] = formData
			const percent = part?.workerOnboardingPart?.state === 'completed' ? 100 : 0
			this.percentPerStep[key] = percent
		})
	}

	@action
	fetchWorkerResumes = async () => {
		const { data } = await apolloClient().query({ query: GET_WORKER_RESUMES_QUERY, variables: { id: authStore.id } })

		this.resumes = data?.worker?.resumes

		setTimeout(() => {
			const __hasResumes = this.resumes?.length > 0
			const experiences = this.stepData[INTEGRATION_OBJECTS.experiences]
			const educations = this.stepData[INTEGRATION_OBJECTS.educations]

			this.syncStepData(INTEGRATION_OBJECTS.experiences, { ...experiences, __hasResumes })
			this.syncStepData(INTEGRATION_OBJECTS.educations, { ...educations, __hasResumes })
		}, 1000)
	}

	@action
	handleSubmitResumes = async ({ resumes }) => {
		const resumesSignedBlobIds = filter(map(resumes, (resume) => !resume._destroy && resume.signedBlobId))?.[0]
		if (resumesSignedBlobIds) {
			const { data } = await apolloClient().mutate({
				mutation: UPDATE_WORKER_RESUMES_MUTATION,
				variables: { id: authStore.id, resumesSignedBlobIds: [resumesSignedBlobIds] },
			})

			this.resumes = data.updateIntegrationWorker.resumes
		} else {
			// HACK: To make form refresh
			this.resumes = [...resumes]
		}
	}

	@action
	handleCompleteActivation = async () => {
		await routerStore.goPage(PATHS.care.profile)
	}

	@action
	handleViewJob = async () => {
		const docRef = fireStore.db.collection(COLECTIONS.last_job_id).doc(authStore.id)
		await docRef.delete()
	}
	@action
	onClickSkip = () => {
		if (this.stepIndex > -1 && this.stepIndex < 6) {
			this.changeActiveStep(this.stepIndex + 1)
		}
	}
	@action
	syncStepData = async (key, formData, dirty = true) => {
		this.dirtyData[key] = dirty
		this.stepData[key] = formData
		// const percent = await CALCULATE_PERCENT[key](formData)
		// this.percentPerStep[key] = percent
	}

	@action
	handleSubmitStepData = async (key, formData) => {
		try {
			if (key === INTEGRATION_OBJECTS.terms && this.totalPercent !== 83) {
				this.changeActiveStep(this.incompletedStepIndex)
				return
			}
			await this.handleSubmitStep(key, formData)
			await this.moveNextStep()
		} catch (error) {
			notifyStore.error(error?.message)
		}
	}

	handleSubmitStep = async (key, formData) => {
		const variables = CONVERT_FORM_TO_SAVE_DATA[key](formData)

		const { data } = await mutateWithTrimmedVariables({
			mutation: UPDATE_WORKER_ONBOARDING_PART_MUTATION,
			variables,
		})

		eventClient.logEvent(EVENTS[key])
		const stepData = CONVERT_PART_TO_FORM_DATA[key](data.updateWorkerOnboardingPart)
		this.stepData[key] = stepData
		const percent = data.updateWorkerOnboardingPart?.state === 'completed' ? 100 : 0
		this.percentPerStep[key] = percent
		await this.fetchNumberCompleteStepOnboarding()
		const docRef = fireStore.db.collection(COLECTIONS.activations).doc(variables.id)
		await docRef.delete()
	}
}

export const careActivationStore = new CareActivationStore()
