import React, { useEffect, useMemo, useRef } from 'react'
import { Backdrop, Box, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core'

import { FIELD_MODE, TRAVEL_EMPLOYMENT_TYPE } from '~/common/constants'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { useEffectOnce, useToggle, useUnmount } from 'react-use'
import { loadingState, observer } from '~/common/mobx.decorator'
import { routerStore } from '~/stores'
import SectionBox from '~/components/section-box'
import * as yup from 'yup'
import BasicInformationForm from '~/features/care-activation/components/basic-information-form'
import ProfessionForm from '~/features/care-activation/components/profession-form'
import EmergencyContactForm from '~/features/care-activation/components/emergency-contact-form'
import { Button } from '~/components/button'
import { FieldArray, Form, Formik } from 'formik'
import { NotifyDialog } from '~/components/notify'
import { OtpDialog } from '~/components/otp-dialog'
import { isArray, isNil, omit } from 'lodash'
import HintBox from '~/features/care-activation/components/hint-box'
import { infoTabStore } from '~/features/care-activation/tabs/info/info-tab.store'
import { careStore } from '~/companies/care/care.store'
import validator from 'validator'
import { ACTIATION_TRACKING_CONSTANT } from '../../care-activation.constant'
import { logClickEvent, logDefaultActionEvent } from '~/common/tracking/event-client.tracking'
import { formatPhoneNumber, validatePhoneNumber } from '~/common/helpers'
import { verifyEmailStore } from '~/components/verify-email/verify-email.store'
import { VerifyEmail } from '~/components/verify-email/verify-email'
import { onboardingProgressStore } from '~/components/onboarding-progress/onboarding-progress.store'

const testEmptyObject = (value) => {
	if (typeof value !== 'object') {
		return false // Not an object
	}

	// Check if the object has no keys
	return Object.keys(value).length > 0
}

const validateSchema = yup.object({
	firstName: yup
		.string()
		.trim()
		.required('Please enter your first name')
		.max(50, 'Maximum characters exceeded')
		.typeError('Please enter your first name')
		.min(2, 'Please ensure First name has at least 2 characters'),
	lastName: yup
		.string()
		.trim()
		.required('Please enter your last name')
		.max(50, 'Maximum characters exceeded')
		.typeError('Please enter your last name')
		.min(2, 'Please ensure Last name has at least 2 characters'),
	email: yup.string().trim().email('Please enter valid email').required('Please enter your email address'),
	phone: yup
		.string()
		.trim()
		.required('Please enter your phone number')
		.typeError('Please enter your phone number')
		.min(12, 'Invalid phone number format, please recheck')
		.test('phone', 'Invalid phone number format, please recheck', (value) => {
			if (isNil(value)) {
				return true
			}
			return value && validatePhoneNumber(value)
		}),
	workerAddress: yup.object().test('has-keys', 'Please enter your permanent address', testEmptyObject),
	preferredShifts: yup
		.array()
		.of(yup.string().test('is-valid-shift', 'Please select your desired shift', (value) => value !== '[]'))
		.min(1, 'Please select your desired shift')
		.typeError('Please select your desired shift'),
	preferredWorkTypes: yup.array().of(yup.string()).min(1, 'Please select your desired employment type').typeError('Please select your desired employment type'),
	preferredLocation: yup.string().when('preferredWorkTypes', {
		is: (value) => isArray(value) && value?.includes(TRAVEL_EMPLOYMENT_TYPE),
		then: yup.string().required('Please select your preferred location'),
	}),
	workerEmergencyContacts: yup.array().of(
		yup.object({
			firstName: yup.string().nullable().trim().max(50, 'Maximum characters exceeded'),
			lastName: yup.string().nullable().trim().max(50, 'Maximum characters exceeded'),
			phone: yup
				.string()
				.nullable()
				.test('phone', 'Invalid phone number format', (value) => {
					if (isNil(value)) {
						return true
					}
					return value && validator.isMobilePhone(value)
				}),
		})
	),
})

export const InfoTab = observer(({ hintText, onNextTab }) => {
	const { t } = useTranslation()

	const {
		phoneVerified,
		workerPhone,
		workerEmail,
		workerNewEmail,
		setWorkerNewEmail,
		infoInitialValues,
		setAllowShowPopup,
		professionInitialValues,
		fetchWorkerBasicInfo,
		fetchEmergencyContacts,
		fetchDisciplinesSpecialties,
		fetchWorkerPhoneVerified,
		contactInitialValues,
		handleSubmitInfo,
		addNewContact,
		contacts,
		syncPersonalInfo,
		setTrackingParams,
		trackingParams,
		primarySpecialty,
		submitPersonalInfo,
		hasAssignedRecruiterOrManager,
		showVerifyPhoneModal,
	} = infoTabStore

	const { fetchOnboardingOverview } = onboardingProgressStore

	const formRef = useRef()
	const theme = useTheme()

	const loadingInfo = loadingState('infoTabStore/fetchWorkerBasicInfo')
	const loadingContacts = loadingState('infoTabStore/fetchEmergencyContacts')

	const loading = useMemo(() => loadingInfo || loadingContacts, [loadingInfo, loadingContacts])
	const loadingSubmit = loadingState('infoTabStore/handleSubmitInfo')

	useEffect(() => {
		if (loading === false && !contacts.length) {
			addNewContact()
			formRef.current &&
				formRef.current.setFieldValue('workerEmergencyContacts', [
					{
						firstName: null,
						lastName: null,
						phone: null,
						relationship: null,
					},
				])
		}
	}, [loading, contacts.length, addNewContact])

	useEffectOnce(() => {
		const { queryParams } = routerStore
		if (queryParams?.showPopup === 'true') setAllowShowPopup(true)
	})

	useEffectOnce(() => {
		Promise.all([fetchWorkerBasicInfo(), fetchDisciplinesSpecialties(), fetchEmergencyContacts(), fetchOnboardingOverview()])
	})

	const [showVerifyPhone, toggleShowVerifyPhone] = useToggle(false)
	const [showVerifiedPhone, toggleShowVerifiedPhone] = useToggle(false)
	const [showPhoneOtp, toggleShowPhoneOtp] = useToggle(false)
	const [showEmailConfirmation, toggleEmailConfirmation] = useToggle(false)
	const [shouldGoNextStep, setShouldGoNextStep] = useToggle(false)
	const [showErrorCodeValidateDispline, setShowErrorCodeValidateDispline] = useToggle(false)

	const handleOnSubmit = async (values, { setSubmitting }) => {
		logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_SUBMIT_STEP_1_BTN_PROFILE_COMPLETION_PERSONAL_INFO)
		setTrackingParams({
			id: values.id,
			first_name: values?.firstName,
			last_name: values?.lastName,
			email: values?.email,
			preferred_shifts: values?.preferredShifts,
			preferred_work_types: values?.preferredWorkTypes,
			addresses: values?.addresses,
			working_preferred_locations: values?.workingPreferredLocations,
			phone: workerPhone,
		})

		if (!primarySpecialty && !submitPersonalInfo.professionInfo) {
			await setShowErrorCodeValidateDispline(true)
			return
		}

		if (primarySpecialty && submitPersonalInfo.professionInfo) {
			await setShowErrorCodeValidateDispline(false)
		}

		// if ((values.phone && (workerPhone ?? '')?.replace('+1', '') !== (values.phone ?? '')?.replace('+1', '')) || !phoneVerified) {
		// 	// toggleShowVerifyPhone(true)
		// 	setShouldGoNextStep(true)
		// 	setSubmitting(false)

		// 	return
		// }

		if (values.email !== workerEmail) {
			setWorkerNewEmail(values.email)
			toggleEmailConfirmation(true)
			setSubmitting(false)

			return
		}
		void handleNextStep({ ...values })
	}

	const handleCancelUpdateNewEmail = (setFieldValue) => {
		toggleEmailConfirmation(false)
		setWorkerNewEmail(workerEmail)
		setFieldValue('email', workerEmail)
	}

	const handleNextStep = async (values) => {
		await handleSubmitInfo(omit(values, ['workerDisciplineSpecialties']), { setSubmitting: false, setErrors: null }).then((res) => res && onNextTab())
	}

	const handleCloseOtp = (values, setFieldValue, setSubmitting) => {
		toggleShowPhoneOtp(false)
		setFieldValue('phone', workerPhone)

		if (values.email !== workerEmail) {
			setWorkerNewEmail(values.email)
			toggleEmailConfirmation(true)
			setSubmitting(false)
		}
	}

	const handleFormError = (isValid, errors) => {
		if (isValid) {
			return
		}

		setTimeout(() => {
			const firstErrorInput = document.querySelectorAll('.MuiInputBase-root.Mui-error > input')?.[0]

			if (firstErrorInput) {
				firstErrorInput.focus()
				firstErrorInput.scrollIntoView({ behavior: 'smooth', block: 'start' })
				window.scrollBy({
					top: -25,
					left: 0,
					behavior: 'smooth',
				})
			}
		}, 200)
	}

	useEffect(() => {
		if (showPhoneOtp) {
			return
		}
		const phoneInput = document.querySelector('.phone-input')
		if (phoneInput) {
			phoneInput.focus()
		}
	}, [showPhoneOtp])

	useEffect(() => {
		if (showVerifyPhone) {
			return
		}
		const phoneInput = document.querySelector('.phone-input')
		if (phoneInput) {
			phoneInput.focus()
		}
	}, [showVerifyPhone])

	useEffect(() => {
		if (showVerifyPhoneModal) {
			toggleShowVerifyPhone(true)
		}
		// eslint-disable-next-line
	}, [showVerifyPhoneModal])

	useUnmount(() => {
		infoTabStore.toggleShowVerifyPhoneModal(false)
	})

	if (loading) {
		return (
			<Backdrop open={loading}>
				<CircularProgress />
			</Backdrop>
		)
	}

	return (
		<Box p={2} mt={1}>
			<HintBox text={hintText} onSkipTab={() => onNextTab()} />
			<SectionBox header={t('PERSONAL_INFORMATION')}>
				<Formik
					innerRef={formRef}
					initialValues={{ ...infoInitialValues, workerEmergencyContacts: contactInitialValues }}
					onSubmit={handleOnSubmit}
					validationSchema={validateSchema}
				>
					{({ handleSubmit, values, setFieldValue, setValues, setSubmitting, isValid }) => {
						useEffect(() => {
							syncPersonalInfo({ ...values })
						}, [values])

						return (
							<Form id="infoForm" onSubmit={handleSubmit}>
								<BasicInformationForm
									mode={FIELD_MODE.edit}
									formValues={values}
									onVerifyPhone={() => (phoneVerified ? toggleShowVerifiedPhone(true) : toggleShowVerifyPhone(true))}
									showPhoneOtp={showPhoneOtp}
									onVerifyEmail={() => {
										verifyEmailStore.setEmail(values.email)
										verifyEmailStore.toggleShowVerifyEmail(true)
									}}
								/>

								<Box mt={2}>
									<SectionBox header={t('PROFESSIONS_SPECIALTIES')}>
										<Box display="flex" flexDirection="column" gridGap={16}>
											{hasAssignedRecruiterOrManager && (
												<Typography variant="subtitle3" style={{ color: theme.colors.content.dark }}>
													{t('ADD_PROFESSIONS_SPECIALTIES_HINT')}
												</Typography>
											)}
											<ProfessionForm
												setShowErrorCode={setShowErrorCodeValidateDispline}
												showErrorCode={showErrorCodeValidateDispline}
												mode={FIELD_MODE.edit}
												initialValues={professionInitialValues}
											/>
										</Box>
									</SectionBox>
								</Box>

								<FieldArray name="workerEmergencyContacts">
									{({ push, remove }) => (
										<Box mt={2}>
											{values.workerEmergencyContacts && values.workerEmergencyContacts.length > 0 ? (
												values.workerEmergencyContacts.map((item, index) => (
													<SectionBox
														headerActions={<div style={{ display: 'none' }} />}
														header={`${t('EMERGENCY_CONTACT')}`}
														onRemove={() => remove(index)}
														hasBorder
													>
														<Box m={2}>
															<EmergencyContactForm mode={FIELD_MODE.edit} formName="workerEmergencyContacts" index={index} />
														</Box>
													</SectionBox>
												))
											) : (
												<Box display="flex" justifyContent="center" my={1}>
													<Typography color="textSecondary">{t('$ERRORS.EMPTY_CONTACTS')}</Typography>
												</Box>
											)}
										</Box>
									)}
								</FieldArray>

								<Box mt={2}>
									<Grid container spacing={2}>
										<Grid item xs={12} md={12}>
											<Button loading={loadingSubmit} fullWidth variant="contained" color="primary" type="submit" onClick={() => handleFormError(isValid)}>
												{t('SAVE_AND_NEXT_STEP')}
											</Button>
										</Grid>
									</Grid>
								</Box>

								<NotifyDialog
									eventName={ACTIATION_TRACKING_CONSTANT.CHANGE_PHONE}
									showButtonClose
									open={showVerifiedPhone}
									okText="Confirm"
									onOk={() => {
										logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CONFIRM_BTN_CHANGE_PHONE)
										toggleShowVerifiedPhone(false)
										toggleShowVerifyPhone(true)
									}}
									title={t('YOUR_PHONE_VERIFIED')}
									onCancel={() => {
										logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CANCEL_BTN_CHANGE_PHONE)
										toggleShowVerifiedPhone(false)
									}}
									onClose={() => {
										logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CANCEL_BTN_CHANGE_PHONE)
										toggleShowVerifiedPhone(false)
									}}
								>
									<Typography color="textSecondary">{t('$MESSAGES.CHANGE_PHONE_WARNING')}</Typography>
								</NotifyDialog>

								<NotifyDialog
									eventName={ACTIATION_TRACKING_CONSTANT.CONFIRM_VERIFY_PHONE}
									showButtonClose
									open={showVerifyPhone && values.phone}
									okText="Confirm"
									onOk={() => {
										logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CONFIRM_BTN_CONFIRM_VERIFY_PHONE)
										toggleShowPhoneOtp(true)
										toggleShowVerifyPhone(false)
									}}
									title={t('VERIFY_PHONE_NUMBER')}
									onCancel={() => {
										logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CANCEL_BTN_CONFIRM_VERIFY_PHONE)
										toggleShowVerifyPhone(false)

										if (values.email !== workerEmail) {
											setWorkerNewEmail(values.email)
											toggleEmailConfirmation(true)
											setSubmitting(false)
										}
									}}
									onClose={() => {
										logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_CANCEL_BTN_CONFIRM_VERIFY_PHONE)
										toggleShowVerifyPhone(false)
									}}
								>
									<Box display="flex" flexDirection="column" gridRowGap={8}>
										<Typography color="textSecondary">
											Please confirm that <strong style={{ color: '#333335' }}>{formatPhoneNumber(values.phone)}</strong> is your phone number to proceed
											verification.
										</Typography>
										<Typography
											color="textSecondary"
											onClick={() => {
												logClickEvent(ACTIATION_TRACKING_CONSTANT.CLICK_UPDATE_PHONE_BTN_CONFIRM_VERIFY_PHONE)
												toggleShowVerifyPhone(false)
											}}
										>
											{t('$MESSAGES.CONFIRM_PHONE_NUMBER_ALT')}
											<span style={{ color: '#00AAE5', cursor: 'pointer' }}>update your phone number</span>
										</Typography>
									</Box>
								</NotifyDialog>

								<NotifyDialog
									eventName={ACTIATION_TRACKING_CONSTANT.SCREEN_CONFIRM_UPDATE_EMAIL}
									open={showEmailConfirmation}
									okText="Confirm"
									onOk={() => {
										const currentTrackingPrams = {
											...trackingParams,
											email: workerNewEmail,
										}
										logDefaultActionEvent(ACTIATION_TRACKING_CONSTANT.CONFIRM_UPDATE_EMAIL_SUBMIT_SUCCESS, currentTrackingPrams)
										setTrackingParams(currentTrackingPrams)

										void handleNextStep({ ...values, email: workerNewEmail }).catch(() =>
											logDefaultActionEvent(ACTIATION_TRACKING_CONSTANT.CONFIRM_UPDATE_EMAIL_SUBMIT_FAILED, currentTrackingPrams)
										)
										toggleEmailConfirmation(false)
									}}
									title={t('$MESSAGES.UPDATE_EMAIL_CONFIRMATION_TITLE')}
									onCancel={() => {
										const currentTrackingPrams = {
											...trackingParams,
											email: workerEmail,
										}
										handleCancelUpdateNewEmail(setFieldValue)
										logDefaultActionEvent(ACTIATION_TRACKING_CONSTANT.CONFIRM_KEEP_EMAIL_SUBMIT_SUCCESS, currentTrackingPrams)
										setTrackingParams(currentTrackingPrams)

										void handleNextStep({ ...values, email: workerEmail }).catch(() =>
											logDefaultActionEvent(ACTIATION_TRACKING_CONSTANT.CONFIRM_KEEP_EMAIL_SUBMIT_FAILED, currentTrackingPrams)
										)
									}}
									cancelText={t('$MESSAGES.KEEP_OLD_ONE')}
								>
									<Typography color="textSecondary">{t('$MESSAGES.UPDATE_EMAIL_CONFIRMATION_CONTENT')}</Typography>
								</NotifyDialog>

								<OtpDialog
									showDialog={showPhoneOtp}
									phoneNumber={values.phone}
									onClose={() => handleCloseOtp(values, setFieldValue, setSubmitting)}
									onCancel={() => handleCloseOtp(values, setFieldValue, setSubmitting)}
									onOk={() => {
										toggleShowPhoneOtp(false)
										void fetchWorkerPhoneVerified(setValues)
										void careStore.fetchWorkerBasicInfo()

										if (values.email !== workerEmail) {
											setWorkerNewEmail(values.email)
											toggleEmailConfirmation(true)
											setSubmitting(false)

											return
										}

										if (shouldGoNextStep) {
											void handleNextStep(values)
										}
										setShouldGoNextStep(false)
									}}
								/>

								<VerifyEmail
									email={values.email}
									showVerifyEmail={verifyEmailStore.showVerifyEmail}
									toggleShowVerifyEmail={verifyEmailStore.toggleShowVerifyEmail}
								/>
							</Form>
						)
					}}
				</Formik>
			</SectionBox>
		</Box>
	)
})
