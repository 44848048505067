import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Box, IconButton, InputAdornment, InputLabel, TextField as MuiTextField } from '@material-ui/core'
import { useField } from 'formik'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { DATE_ENCRYPT_PATTERN, FIELD_MODE, TIME_FORMAT_SAVE, TIME_FORMAT_VIEW } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import moment from 'moment'
import { labelStyle } from '../date-field/date-field.style'
import { ClearAutoCompleteSvg, ClockSvg } from '~/components/icons'
import { textFieldStyle } from '../text-field/text-field.style'

export const TimeField = ({
	name,
	validate,
	endLabel,
	label,
	placeholder,
	mode,
	shrink = true,
	variant = 'outlined',
	size = 'small',
	textFieldVariant,
	viewFormat,
	saveFormat,
	disableTyping = false,
	clearable = true,
	...props
}) => {
	const { t } = useTranslation()
	const [field, meta] = useField({
		name,
		validate: mode === FIELD_MODE.edit && validate,
	})
	const [focused, focusProps] = useFieldFocused(field)
	const [inputValue, updateInputValue] = useState(field.value)
	const error = useFieldError(meta)

	useEffect(() => {
		if (field.value !== inputValue && !focused) {
			updateInputValue(field.value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [field.value, focused])

	useEffect(() => {
		if (field.value !== inputValue) {
			field.onChange({ target: { name, value: inputValue } })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inputValue])

	const validateTimeFormat = (value) => {
		if (!value) return true
		const timeRegex = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/
		return timeRegex.test(value)
	}

	const formatTimeValue = (value) => {
		if (!value) return value
		// Handle when user types single digit for hour (e.g. "7:12" -> "07:12")
		const match = value.match(/^(\d{1}):(\d{2})$/)
		if (match) {
			return `0${match[1]}:${match[2]}`
		}
		return value
	}

	const handleChange = useCallback(
		(event) => {
			const value = event.target.value
			if (disableTyping) return

			// Allow empty value or partial input while typing
			if (!value || value.length <= 5) {
				const formattedValue = formatTimeValue(value)
				updateInputValue(formattedValue)
			}
		},
		[updateInputValue, disableTyping]
	)

	const handleBlur = useCallback(
		(event) => {
			const value = event.target.value
			const formattedValue = formatTimeValue(value)
			if (!validateTimeFormat(formattedValue)) {
				updateInputValue('')
			} else {
				updateInputValue(formattedValue)
			}
			field.onBlur(event)
		},
		[field]
	)

	const handleClear = useCallback(() => {
		updateInputValue('')
		field.onChange({ target: { name, value: '' } })
		field.onBlur({ target: { name } })
	}, [field, name, updateInputValue])

	const encrypted = useMemo(() => DATE_ENCRYPT_PATTERN.test(field.value), [field.value])

	const fieldValue = useMemo(() => (encrypted || !field.value ? null : moment(field.value, saveFormat)), [encrypted, field.value, saveFormat])

	if (mode === FIELD_MODE.view) {
		return <LabelField label={t(label)} displayValueFormat={() => (encrypted ? field.value : fieldValue?.format(TIME_FORMAT_VIEW))} />
	}
	if (mode === FIELD_MODE.viewDisable) {
		return <LabelField label={t(label)} disabled displayValueFormat={() => (encrypted ? field.value : fieldValue?.format(TIME_FORMAT_VIEW))} />
	}

	return (
		<>
			<Box display="flex" justifyContent="space-between">
				<InputLabel shrink htmlFor={name} css={labelStyle}>
					{t(label)}
				</InputLabel>
				{endLabel}
			</Box>

			<Box position="relative">
				<input
					type="time"
					id={`${name}-hidden`}
					style={{
						position: 'absolute',
						top: 0,
						left: 0,
						width: '100%',
						height: '100%',
						opacity: 0,
						pointerEvents: 'none',
					}}
					value={inputValue || ''}
					onChange={(e) => {
						updateInputValue(e.target.value)
					}}
					onFocus={(e) => e.target.showPicker()}
				/>

				<MuiTextField
					type="text"
					id={name}
					placeholder={placeholder && t(placeholder)}
					helperText={error || (inputValue && !validateTimeFormat(inputValue) ? t('INVALID_TIME_FORMAT') : '')}
					error={!!error || (inputValue && !validateTimeFormat(inputValue))}
					css={textFieldStyle}
					InputProps={{
						...focusProps,
						placeholder: 'HH:mm',
						endAdornment: (
							<InputAdornment position="end">
								{clearable && inputValue && (
									<IconButton className={'clear-button'} style={{ padding: 0, marginRight: '15px' }} onClick={handleClear}>
										<ClearAutoCompleteSvg style={{ color: '#333335' }} />
									</IconButton>
								)}
								<IconButton 
									style={{ padding: 0 }} 
									onClick={() => {
										const hiddenInput = document.getElementById(`${name}-hidden`)
										if (hiddenInput) {
											hiddenInput.focus()
										}
									}}
								>
									<ClockSvg style={{ color: '#333335' }} />
								</IconButton>
							</InputAdornment>
						),
					}}
					InputLabelProps={{
						shrink,
					}}
					{...field}
					value={inputValue || ''}
					onChange={handleChange}
					onBlur={handleBlur}
					{...props}
				/>
			</Box>
		</>
	)
}

TimeField.defaultProps = {
	label: 'SELECT_TIME',
	placeholder: '$PLACEHOLDERS.SELECT_TIME',
	saveFormat: TIME_FORMAT_SAVE,
	clearable: true,
	mode: FIELD_MODE.edit,
	textFieldVariant: 'outlined',
	viewFormat: TIME_FORMAT_VIEW,
}
