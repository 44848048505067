import CrossmedBannerUrl from './assets/crossmed/banner.webp'
import CrossmedLogoUrl from './assets/crossmed/logo.png'
import CrossmedAnoBannerUrl from './assets/crossmed/ano-banner.webp'
import ConcentricBannerUrl from './assets/concentric/banner.webp'
import ConcentricLogoUrl from './assets/concentric/logo.png'
import ConcentricAnoBannerUrl from './assets/concentric/ano-banner.webp'
import ConcentricCompleteOnboardingUrl from './assets/concentric/complete-onboarding.png'
import CrossmedCompleteOnboardingUrl from './assets/crossmed/complete-onboarding.png'

export const BANNER_URLS = {
	crossmed: CrossmedBannerUrl,
	concentric: ConcentricBannerUrl,
}

export const LOGO_URLS = {
	crossmed: CrossmedLogoUrl,
	concentric: ConcentricLogoUrl,
}

export const ANO_BANNER_URLS = {
	crossmed: CrossmedAnoBannerUrl,
	concentric: ConcentricAnoBannerUrl,
}

export const COMPLETE_ONBOARDING_URLS = {
	crossmed: CrossmedCompleteOnboardingUrl,
	concentric: ConcentricCompleteOnboardingUrl,
}

export const DEFAULT_BANNER = BANNER_URLS.crossmed
export const DEFAULT_LOGO = LOGO_URLS.crossmed
export const DEFAULT_ANO_BANNER = ANO_BANNER_URLS.crossmed
