import React, { useState } from 'react'
import { Box, SvgIcon, Typography } from '@material-ui/core'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { CheckedCircleGreenSvg, ErrorWarningSvg } from '~/components/icons'
import { Button } from '~/components/button'
import { css } from 'styled-components'
import { NotifyDialog } from '~/components/notify'
import { ADDITION_SKILL_CHECKLIST_STATUS } from '~/common/constants'
import { makeStyles } from '@material-ui/core/styles'
import { checklistsStore } from '../tabs/checklists/checklists.store'

const actionButtonStyle = css`
	background-color: #fff;
	border-radius: 48px;
	color: ${({ theme }) => theme.colors.secondary.main};
	padding: 8px 12px;
	&.complete-button {
		color: ${({ theme }) => theme.colors.hyperlink.medium};
	}
	.MuiButton-label {
		font-weight: 700;
	}
`

const completeWrapperStyle = css`
	background-color: #43ba0a12;
	border-radius: 8px;
	flex-wrap: wrap;

	@media (max-width: 1050px) {
		.text-container {
			width: 100%;
		}
		flex-direction: column;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}

	.MuiButton-label {
		font-weight: 700;
	}
`

const incompleteWrapperStyle = css`
	background-color: #ff9b0412;
	border-radius: 8px;
	flex-wrap: wrap;

	@media (max-width: 1050px) {
		.text-container {
			width: 100%;
		}
		flex-direction: column;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}
	.MuiButton-label {
		font-weight: 700;
	}
`

const actionsContainerStyle = css`
	display: flex;
	gap: 10px;
`

const completedTextStyle = css`
	${({ theme }) => theme.typography.title2};
	color: ${({ theme }) => theme.palette.status.success.medium};
`

const incompleteTextStyle = css`
	${({ theme }) => theme.typography.title2};
	color: ${({ theme }) => theme.palette.status.warning.medium};
`

const nameCheckListStyle = css`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	@media (max-width: 1050px) {
		max-width: 100%;
	}
`

const useStyle = makeStyles((theme) => ({
	confirmPopup: {
		maxWidth: '400px',
		'&.MuiPaper-root': {
			maxWidth: '400px',
			'& .title': {
				width: '305px',
			},
		},
	},
}))

const ChecklistItem = ({ name, completed, url, status, showUnaButton = true, onTrackingEvent, resultFile, id }) => {
	const { t } = useTranslation()
	const [showConfirmPopup, setShowConfirmPopup] = useState(false)
	const { downloadResultFile } = checklistsStore
	const classes = useStyle()

	const downloadDocument = async () => {
		if (id) await downloadResultFile(id).then((file) => window.open(file.fileUrl, '_blank'))
	}

	return (
		<Box px={2} py={2} display="flex" alignItems="center" justifyContent="space-between" css={completed ? completeWrapperStyle : incompleteWrapperStyle}>
			<Box display="flex" flexDirection="column" gridGap={8} pr={2} className="text-container">
				<Typography variant="h4" css={nameCheckListStyle}>
					{name}
				</Typography>

				<Box display="flex" alignItems="center" gridGap={4}>
					{!showUnaButton ? (
						<>
							{status === ADDITION_SKILL_CHECKLIST_STATUS.completed ? (
								<>
									<SvgIcon style={{ width: '16px', height: '16px', fill: 'transparent' }} viewBox="0 0 20 20" component={CheckedCircleGreenSvg} />
									<Typography css={completedTextStyle}>{t('COMPLETED')}</Typography>
								</>
							) : status === ADDITION_SKILL_CHECKLIST_STATUS.assigned ? (
								<>
									<SvgIcon style={{ width: '16px', height: '16px', fill: 'transparent' }} viewBox="0 0 16 16" component={ErrorWarningSvg} />
									<Typography css={incompleteTextStyle}>{t('ASSIGNED')}</Typography>
								</>
							) : (
								<>
									<SvgIcon style={{ width: '16px', height: '16px', fill: 'transparent' }} viewBox="0 0 16 16" component={ErrorWarningSvg} />
									<Typography css={incompleteTextStyle}>{t('NOT_ASSIGNED')}</Typography>
								</>
							)}
						</>
					) : (
						<>
							{completed ? (
								<>
									<SvgIcon style={{ width: '16px', height: '16px', fill: 'transparent' }} viewBox="0 0 20 20" component={CheckedCircleGreenSvg} />
									<Typography css={completedTextStyle}>{t('COMPLETED')}</Typography>
								</>
							) : (
								<>
									<SvgIcon style={{ width: '16px', height: '16px', fill: 'transparent' }} viewBox="0 0 16 16" component={ErrorWarningSvg} />
									<Typography css={incompleteTextStyle}>{t('INCOMPLETE_CHECKLIST')}</Typography>
								</>
							)}
						</>
					)}
				</Box>
			</Box>
			<div css={actionsContainerStyle}>
				{resultFile && id && (
					<Button
						fullWidth={false}
						variant="contained"
						color="primary"
						className={'complete-button'}
						css={actionButtonStyle}
						onClick={() => {
							downloadDocument()
						}}
					>
						{t('DOWNLOAD_RESULT_PDF')}
					</Button>
				)}
				{/* {showUnaButton && url && ( */}
				<Button
					fullWidth={false}
					variant="contained"
					color="primary"
					className={completed ? 'complete-button' : ''}
					css={actionButtonStyle}
					onClick={() => {
						if (onTrackingEvent) onTrackingEvent()
						setShowConfirmPopup(true)
					}}
				>
					{completed ? t('VIEW_DETAILS_IN') : t('LET_BEGIN')}
				</Button>
				{/* )} */}
			</div>

			<NotifyDialog
				open={showConfirmPopup}
				title={t('JOTFORM_TITLE_CONFIRM')}
				isShowOkText={false}
				showButtonClose={false}
				onClose={() => setShowConfirmPopup(false)}
				PaperProps={{ classes: { root: classes.confirmPopup } }}
			>
				<Typography color="textSecondary">{t('JOTFORM_CONTENT_CONFIRM')}</Typography>
				<Box display="flex" flexDirection="column" gridGap={8} mt={3}>
					<Button
						color={'primary'}
						onClick={() => {
							window.open(url, '_blank')
							setShowConfirmPopup(false)
						}}
					>
						{t('PROCEED')}
					</Button>
					<Button variant="outlined" color="primary" onClick={() => setShowConfirmPopup(false)}>
						{t('CANCEL')}
					</Button>
				</Box>
			</NotifyDialog>
		</Box>
	)
}

export default ChecklistItem
