import { action, observable, store } from '~/common/mobx.decorator'
import { notifyStore } from '~/stores'
import { RECAPTCHA_CLIENT_KEY } from '~/common/helpers'
import { apolloClient, GET_REMAINING_EMAIL_OTP_REQUESTS_QUERY, SEND_EMAIL_OTP_CODE_MUTATION, VERIFY_EMAIL_OTP_CODE_MUTATION } from '~/common/apollo'
import { ERROR_CODE } from '~/common/constants'

const RESENT_TIME_IN_SECONDS = 60 * 1000
const RESENT_TIME_INTERVAL = 1000

@store()
class EmailOtpDialogStore {
	@observable showDialogOtp = false
	@observable email = ''
	@observable phoneNumber = ''
	@observable phoneInvalidConfirm = false
	@observable phoneInvalidMessage = ''
	@observable sendOtpCodeDone = false
	@observable remainingOtpSessionRetries
	@observable remainingOtpTotalRetries
	@observable initialErrorCode
	@observable initialErrorMessage
	@observable errorCode
	@observable errorMessage
	@observable submitErrorCode
	@observable submitErrorMessage
	@observable loadingInitial = false

	@observable resendTimeLeft = RESENT_TIME_IN_SECONDS
	@observable intervalId
	@observable timerRunning = false

	@action
	startTimer = () => {
		this.intervalId = setInterval(() => {
			if (this.resendTimeLeft <= 0) {
				clearInterval(this.intervalId)
				this.timerRunning = false
				return
			}
			this.resendTimeLeft = this.resendTimeLeft - RESENT_TIME_INTERVAL
			this.timerRunning = true
		}, RESENT_TIME_INTERVAL)
	}

	@action
	resetTimer = () => {
		clearInterval(this.intervalId)
		this.timerRunning = false
		this.resendTimeLeft = RESENT_TIME_IN_SECONDS
	}

	@observable
	setPhoneNumber = (value) => {
		this.phoneNumber = value
	}

	@action
	setShowDialogOtp = (value) => {
		this.showDialogOtp = value
	}

	@action
	fetchInitialVerifyData = async (email) => {
		this.loadingInitial = true
		this.resetTimer()
		const { data, errors } = await apolloClient().query({
			query: GET_REMAINING_EMAIL_OTP_REQUESTS_QUERY,
			variables: {
				email: email,
			},
		})

		const errorCode = errors?.[0]?.extensions?.error

		if (errorCode) {
			this.initialErrorCode = errorCode
			this.initialErrorMessage = errors?.[0]?.message
			this.loadingInitial = false

			return
		}

		this.initialErrorCode = ''
		this.initialErrorMessage = ''

		this.remainingOtpTotalRetries = data?.checkRemainingEmailVerificationRequests?.maxAttempts
		this.remainingOtpSessionRetries = data?.checkRemainingEmailVerificationRequests?.remainingEmailSessionResendCode

		void this.handleSendOTP({ email }, {}).finally(() => {
			this.loadingInitial = false
		})
	}

	@action
	handleSendOTP = async (variables, { setErrors }) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const recaptchaToken = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, { action: 'sendOtpCode' })
					const { data } = await apolloClient().mutate({
						mutation: SEND_EMAIL_OTP_CODE_MUTATION,
						variables: {
							...variables,
							recaptchaToken,
						},
					})

					this.remainingOtpSessionRetries = data?.requestVerificationEmail?.remainingEmailSessionResendCode
					// this.remainingOtpTotalRetries = data?.requestVerificationEmail?.remainingTotalResendCode

					this.errorCode = ''
					this.errorMessage = ''

					this.submitErrorCode = ''
					this.submitErrorMessage = ''

					this.loadingInitial = false

					notifyStore.success('$MESSAGES.SEND_OTP')
				} catch (error) {
					const { graphQLErrors } = error
					this.errorCode = graphQLErrors?.[0]?.extensions?.error
					this.errorMessage = graphQLErrors?.[0]?.message

					if (!Object.values(ERROR_CODE).includes(this.errorCode)) {
						typeof setErrors === 'function' ? setErrors({ otpCode: this.errorMessage }) : void notifyStore.error(this.errorMessage)
						this.errorCode = ''
					}
					this.loadingInitial = false
				} finally {
					if (!this.timerRunning) {
						this.resetTimer()
						this.startTimer()
					}
				}
			})
		})
	}

	@action
	handleSubmitOTP = async (variables, { setErrors }, onOk) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const recaptchaToken = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, { action: 'verifyOtpCode' })

					const { data } = await apolloClient().mutate({
						mutation: VERIFY_EMAIL_OTP_CODE_MUTATION,
						variables: {
							...variables,
							recaptchaToken,
						},
					})

					if (data?.verifyEmail?.success) {
						notifyStore.success('$MESSAGES.EMAIL_VERIFIED_SUCCESS')
						this.submitErrorCode = ''
						this.submitErrorMessage = ''

						this.errorCode = ''
						this.errorMessage = ''

						typeof onOk === 'function' && onOk()
						resolve(true)
					}
				} catch (error) {
					const { graphQLErrors } = error
					this.submitErrorCode = graphQLErrors?.[0]?.extensions?.error
					this.submitErrorMessage = graphQLErrors?.[0]?.message

					if (!Object.values(ERROR_CODE).includes(this.submitErrorCode)) {
						typeof setErrors === 'function' && setErrors({ otpCode: this.submitErrorMessage })
					} else {
						notifyStore.error(this.submitErrorMessage)
					}
				}
			})
		})
	}

	@action
	setPhoneInvalidConfirm = (value) => {
		this.phoneInvalidConfirm = value
	}

	@action
	setEmail = (value) => {
		this.email = value
	}
}

export const emailOtpDialogStore = new EmailOtpDialogStore()
