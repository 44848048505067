import axios from 'axios'
import { authStore, appStore } from '~/stores'

export const createAxios = (headers) => {
	return axios.create({
		baseURL: appStore?.trackingApiUrl || process.env.REACT_APP_TRACKING_API_URL,
		headers: {
			'x-api-key': appStore?.trackingApiKey || process.env.REACT_APP_TRACKING_API_KEY,
			...headers,
		},
		method: 'PUT',
		timeout: 30000,
	})
}

const records = async (dataValue) => {
	const { data, name } = dataValue || {}
	const { ui_key, component_name, location, session_id, ...rest } = data || {}
	const anonymousId = await window.host.getId()
	const ip = await window.host.getIP()
	const platform = await window.host.getBrowserPlatform()
	const platform_version = await window.host.getBrowserVersion()
	const userAgent = await window.host.getUserAgent()
	const timezone = await window.host.getTimezone()
	const timestamp = +Math.floor(Date.now() / 1000)
	try {
		const trackingData = {
			Records: [
				{
					data: {
						common_properties: {
							anonymous_id: anonymousId,
							user_id: authStore.id,
							app_build_version: null,
							app_bundle: null,
							app_version: null,
							device_id: anonymousId,
							ip: ip,
							location: location,
							platform: platform,
							platform_version: platform_version,
							user_agent: userAgent,
						},
						event_properties: {
							...rest,
						},
						misc_properties: {
							component_name: component_name,
							key: ui_key,
							session_id: session_id,
						},
					},
					tenant_id: appStore?.id || process.env.REACT_APP_COMPANY_ID,
					source: 'opus_web_portal',
					is_anonymous: !authStore.id,
					timestamp: timestamp,
					timezone: timezone,
					name: name,
				},
			],
		}
		if (authStore.id) {
			return await createAxios().put('/records', trackingData)
		} else {
			return await createAxios().put('/anonymous', trackingData)
		}
	} catch (error) {
		return null
	}
}

export const httpRequestTracking = {
	record: records,
}
