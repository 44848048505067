import { css } from 'styled-components'

export const wrapperStyle = css`
	border: 1px solid #e6e6e6;
	border-radius: 8px;
`

export const redLabel = css`
	font-weight: 600 !important;
	color: ${({ theme }) => theme.palette.status.error.medium};
`

export const blueLabel = css`
	font-weight: 600 !important;
	color: ${({ theme }) => theme.palette.secondary.main};
`
export const redIcon = css`
	& path {
		fill: ${({ theme }) => theme.palette.status.error.medium};
	}
`
