import React from 'react'
import { apolloClient, SIGNIN_WORKER_MUTATION, UPDATE_WORKER_PASSWORD } from '~/common/apollo'
import { RECAPTCHA_CLIENT_KEY } from '~/common/helpers'
import { action, store, toJS } from '~/common/mobx.decorator'
import { appStore, authStore, fireStore, notifyStore, routerStore } from '~/stores'
import { PATHS, PROVIDER_NAME, SIGN_IN_EVENT } from '~/common/constants'
import { observable } from 'mobx'
import { logClickEvent, logDefaultActionEvent } from '~/common/tracking/event-client.tracking'

export const ERROR_TYPES = {
	locked_access: 'locked_access',
	temp_password_expired: 'temp_password_expired',
}
@store()
class CommonSigninStore {
	@observable phoneVerifyAt = null
	@observable changePasswordRequired = false
	@observable workerID = ''
	@observable authTokenTemp = ''

	@action
	loginLinkedIn = async (data, history) => {
		const providerData = {
			email: data?.email,
			clientId: data?.clientId,
			workerSocialFirstName: data?.workerSocialFirstName,
			workerSocialLastName: data?.workerSocialLastName,
			providerName: data?.providerName,
			clientToken: data?.clientToken,
			code: data?.code,
			redirectUri: data?.redirectUri,
		}
		return fireStore.signInWithOauth(history, providerData)
	}

	@action
	handleSuccess = async (data, history) => {
		if (data) {
			this.loginLinkedIn(
				{
					clientId: appStore.linkedInClientId,
					providerName: PROVIDER_NAME.linkedin,
					code: data,
					redirectUri: `${window.location.origin}/linkedin`,
				},
				history
			)
		}
	}

	@action
	login = async (variables, { setErrors }) => {
		return new Promise((resolve, reject) => {
			window.grecaptcha.ready(async () => {
				try {
					const token = await window.grecaptcha.execute(RECAPTCHA_CLIENT_KEY, {
						action: 'signInWorker',
					})

					let updatedVariables = {
						recaptchaToken: token,
						...variables,
					}
					const {
						data: { signInWorker },
					} = await apolloClient().mutate({
						mutation: SIGNIN_WORKER_MUTATION,
						variables: updatedVariables,
						context: {
							clientName: 'public',
						},
					})

					//const { authToken, worker, notificationChannel } = signInWorker
					const { notificationChannel, authToken, worker } = signInWorker
					await authStore.handleAuthAnonymous(false)
					this.workerID = worker?.id
					logDefaultActionEvent(SIGN_IN_EVENT.SIGNIN_SUBMIT_SUCCESS, {
						email: variables?.email,
					})

					if (worker?.user?.changePasswordRequired) {
						this.changePasswordRequired = true
						this.authTokenTemp = authToken
						await authStore.changeProfile(worker, notificationChannel)
					} else {
						await authStore.changeProfile(worker, notificationChannel)
						await authStore.changeToken(authToken)
						notifyStore.success('Successfully signed in')
					}

					resolve()
				} catch (error) {
					const { graphQLErrors } = error
					logDefaultActionEvent(SIGN_IN_EVENT.SIGNIN_SUBMIT_FAILED, {
						email: variables?.email,
					})

					if (graphQLErrors?.[0]?.extensions?.error === 'temp_password_expired') {
						notifyStore.error(
							<span
								dangerouslySetInnerHTML={{
									__html: `The password you entered has expired and is only valid for <strong style="color:#333335">72 hours.</strong>\n\nWe have sent a new temporary password to your email. Please check it and follow the instructions to reset your password.`,
								}}
							/>,
							{ title: 'Temporary password expired', okText: 'I got it', showDialog: true, keepOriginal: true }
						)
					} else if (graphQLErrors?.[0]?.extensions?.error === 'wrong_username_or_password_error') {
						console.log(graphQLErrors?.[0]?.extensions?.error === 'locked_access_error')
						notifyStore.error(
							`It looks like you entered incorrect credentials. You have ${
								5 - (graphQLErrors?.[0]?.extensions?.failed_attempts || 0)
							} attempts remaining. After 5 failed attempts, your account will be temporarily locked.`,
							{
								title: 'Login failed',
								okText: 'Try again',
								showDialog: true,
								keepOriginal: true,
								onOk: (key) => {
									logClickEvent(SIGN_IN_EVENT.CLICK_TRY_AGAIN_BTN)
									notifyStore.closeDialog(key)
								},
							}
						)
					} else if (graphQLErrors?.[0]?.extensions?.error === 'locked_access_error') {
						notifyStore.error('Your account are now temporarily locked. Please check your email for the unlock instruction.', {
							title: 'You have failed to sign in for 5 times',
							okText: 'I got it',
							showDialog: true,
							keepOriginal: true,
							onOk: (key) => {
								logClickEvent(SIGN_IN_EVENT.CLICK_I_GOT_IT_BTN)
								notifyStore.closeDialog(key)
							},
						})
					} else {
						notifyStore.error(error?.message)
					}
					resolve()
				}
			})
		})
	}

	@action
	loginSSO = async (variables) => {
		const token = toJS(authStore.authorization)
		const customHeaders = {}
		const getTokenCandidate = window.localStorage.getItem('candidate-token')
		if (token) {
			customHeaders['Auth-Token'] = getTokenCandidate
		}

		customHeaders['company-id'] = process.env.REACT_APP_COMPANY_ID
		customHeaders['OPUS_POLICIES'] = 'IRC'

		try {
			const {
				data: { signInWorker },
			} = await apolloClient().mutate({
				mutation: SIGNIN_WORKER_MUTATION,
				variables: variables,
				context: {
					headers: customHeaders,
				},
			})

			//const { authToken, worker, notificationChannel } = signInWorker
			const { authToken, worker, notificationChannel } = signInWorker
			await authStore.handleAuthAnonymous(false)
			this.changePasswordRequired = worker?.changePasswordRequired
			this.workerID = worker?.id
			if (worker?.changePasswordRequired) {
				await authStore.changeToken(authToken)
			} else {
				await authStore.changeProfile(worker, notificationChannel)
				await authStore.changeToken(authToken)
			}
			await notifyStore.success('Login successfully')
		} catch (error) {
			await notifyStore.error(error.message)
		}
	}

	@action
	toogleChangePasswordRequired = async (value, authToken) => {
		this.changePasswordRequired = value
		this.authTokenTemp = authToken
	}

	@action
	handleUpdatePassword = async (values) => {
		const tokenType = authStore.tokenType
		try {
			await apolloClient().mutate({
				mutation: UPDATE_WORKER_PASSWORD,
				variables: { id: this.workerID, password: values?.password },
				context: { headers: { Authorization: `${tokenType === 'bearer' ? 'Bearer' : ''} ${this.authTokenTemp?.accessToken}` } },
			})
			this.changePasswordRequired = false
			await authStore.changeToken(this.authTokenTemp).then(() => {
				this.authTokenTemp = {}
				routerStore.goPage(PATHS.care.home)
			})

			await notifyStore.success('Your password has been updated')
		} catch (error) {
			await notifyStore.error(error.message)
		}
	}
}

export const commonSigninStore = new CommonSigninStore()
