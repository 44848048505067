import React, { useMemo, useRef } from 'react'
import { Box, ClickAwayListener, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { useField } from 'formik'
import { useFieldError, useFieldFocused } from '~/common/hooks'
import { useTranslation } from '@opus/web.core.hooks.use-translation'
import { DATE_ENCRYPT_PATTERN, DATE_FORMAT_SAVE, DATE_FORMAT_TEXT, FIELD_MODE } from '~/common/constants'
import { LabelField } from '@opus/web.core.form.label-field'
import { CalendarSvg } from '~/components/icons'
import moment from 'moment'
import { labelStyle } from './date-field.style'
import { viewLabelStyle } from '~/components/fields/text-field/text-field.style'
import InputLabel from '@material-ui/core/InputLabel'
import { useToggle } from 'react-use'

export const DateField = ({
	name,
	validate,
	label,
	placeholder,
	mode,
	saveFormat,
	handleOnChange,
	textFieldVariant,
	viewFormat,
	endLabel,
	disableTyping = false,
	isUseShouldDisableDateProp = false,
	...props
}) => {
	const { t } = useTranslation()
	const [open, setOpen] = useToggle(false)
	const [field, meta] = useField({ name, validate: mode === FIELD_MODE.edit && validate })
	const [focusProps, { onBlur }] = useFieldFocused(field)
	const error = useFieldError(meta)
	const controllAutoOnChange = useRef(false)

	const handleChange2 = React.useCallback(
		(date) => {
			if (controllAutoOnChange.current || !field.value) {
				const value = date?.format(saveFormat)
				field.onChange({ target: { name, value } })
			}
			controllAutoOnChange.current = true
		},
		[field, name, saveFormat]
	)

	const handleChange = React.useCallback(
		(date) => {
			const value = date ? date?.format(saveFormat) : null
			field.onChange({ target: { name, value } })
			handleOnChange && handleOnChange(value)
		},
		[field, name, saveFormat, handleOnChange]
	)

	const encrypted = useMemo(() => DATE_ENCRYPT_PATTERN.test(field.value), [field.value])

	const fieldValue = useMemo(() => (encrypted || !field.value ? null : moment(field.value, saveFormat)), [encrypted, field.value, saveFormat])

	if (mode === FIELD_MODE.view) {
		return <LabelField css={viewLabelStyle} label={t(label)} displayValueFormat={() => (encrypted ? field.value : fieldValue?.format(DATE_FORMAT_TEXT))} />
	}

	return (
		<ClickAwayListener onClickAway={() => setOpen(false)}>
			<div>
				<DatePicker
					open={open}
					onOpen={() => setOpen(true)}
					onClose={() => {
						setOpen(false)
					}}
					PopperProps={{
						disablePortal: true,
					}}
					minDate={new Date(1700, 0, 1)}
					{...field}
					value={fieldValue}
					inputFormat={DATE_FORMAT_TEXT}
					{...props}
					shouldDisableDate={props.shouldDisableDate}
					toolbarFormat={DATE_FORMAT_TEXT}
					allowSameDateSelection
					onChange={isUseShouldDisableDateProp ? handleChange2 : handleChange}
					openPickerIcon={<CalendarSvg />}
					renderInput={(renderProps) => {
						return (
							<>
								<Box display="flex" justifyContent="space-between" mb="6px">
									<InputLabel shrink htmlFor={name} css={labelStyle}>
										{t(label)}
									</InputLabel>
									{endLabel}
								</Box>

								<TextField
									{...renderProps}
									variant={textFieldVariant}
									size="small"
									helperText={error}
									error={!!error}
									placeholder={t(placeholder)}
									fullWidth
									onBlur={onBlur}
									inputProps={{
										...renderProps.inputProps,
										value: encrypted ? field.value : renderProps?.inputProps.value,
										...focusProps,
										placeholder: t(placeholder),
										onClick: () => setOpen(),
										readOnly: disableTyping
									}}
									InputLabelProps={{ shrink: true }}
									InputProps={{ style: { color: 'inherit' }, ...renderProps.InputProps }}
									onKeyDown={(e) => (disableTyping ? e.preventDefault() : undefined)}
								/>
							</>
						)
					}}
				/>
			</div>
		</ClickAwayListener>
	)
}

DateField.defaultProps = {
	label: 'SELECT_DATE',
	placeholder: '$PLACEHOLDERS.SELECT_DATE',
	saveFormat: DATE_FORMAT_SAVE,
	clearable: true,
	mode: FIELD_MODE.edit,
	minDate: new Date(1700, 0, 1), // Start of year 1700,
	textFieldVariant: 'outlined',
	viewFormat: DATE_FORMAT_TEXT,
}
