import { css } from 'styled-components'

export const tabStyle = css`
	&.MuiTabs-root {
		min-width: 100%;
	}
	.MuiTabs-flexContainer {
		color: ${({ theme }) => theme.palette.text.secondary};
		border-bottom: solid 1px ${({ theme }) => theme.palette.content.light};

		.MuiTab-root {
			opacity: 1;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;

			&.Mui-selected {
				color: ${({ theme }) => theme.palette.primary.main};
				font-weight: 800;
			}
		}

		> .MuiButtonBase-root.MuiTab-root {
			min-width: fit-content;
			flex-grow: 1;
		}
	}
	@media (max-width: 500px) {
		.MuiTab-wrapper {
			font-size: 10px !important;
		}
	}

	.MuiTabs-indicator {
		height: 2px;
	}
`

export const headerStyle = css`
	height: 80px;
	border-top: none;
	background-color: #d8f4f7;

	display: flex;
	align-items: center;
	padding: 0 16px;

	.progress {
		position: relative;
		margin-right: 8px;

		.MuiCircularProgress-root {
			&:first-child {
				color: #dcdcdc;
			}

			&:last-child {
				position: absolute;
				top: 0;
				left: 0;
				color: #fcbe12;
			}
		}
	}

	> div:last-child {
		margin-left: auto;
		opacity: 0.7;
		width: 60%;
	}
`

export const percentStyle = css`
	font-size: 25px;
	font-weight: 500;
`

export const descriptionStyle = css`
	line-height: 1.33;
	white-space: normal;

	.primary-color {
		margin-bottom: 5px;
		color: ${({ theme }) => theme.palette.text.secondary};
	}

	@media (max-width: 500px) {
		.mobile-text {
			font-size: 12px;
		}
	}
`

export const hintBoxStyle = css`
	background-color: ${({ theme }) => theme.palette.background.element.veryLight};
	border-radius: 8px;
`

export const hintBoxPercentageStyle = css`
	background-color: #f6fbff;
	border-radius: 8px;
	gap: 16px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	.text-container {
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		gap: 8px;
	}
`

export const skipStepStyle = css`
	width: auto;
	padding: 0px;
	color: ${({ theme }) => theme.colors.primary.main};
	font-size: 14px;
	font-style: normal;
	font-weight: 800;
	line-height: 22px;
	&:hover {
		cursor: pointer;
	}
	& > span {
		color: ${({ theme }) => theme.palette.secondary.main};
		${({ theme }) => theme.typography.title2};
	}
`

export const verifiedWrapperStyle = css`
	width: 100%;
	.email-address {
		overflow: hidden;
		display: inline-block;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.MuiInputBase-root > .MuiBox-root {
		word-break: break-word;
		width: 100%;
	}
`

export const profileCompletedStatusStyle = css`
	.title {
		padding: 16px 16px 16px 24px;
		gap: 8px;
		width: 100%;
		height: 64px;
		position: relative;
		background: rgba(255, 155, 4, 0.07);
		border-radius: 0px;
		font-weight: 700;
		color: ${({ theme }) => theme.colors.status.warning.medium};
	}
`
