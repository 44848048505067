import gql from 'graphql-tag'

export const GET_COMPANY_INFO_QUERY = gql`
	query getCompanyInfo {
		appInit {
			tenant {
				name
				timezone
			}
			payrollConfig {
				providerName
				payrollUrl
			}

			tenantContacts {
				email
				phone
				contactType
			}

			tenantConfig {
				termUrl
				contactEmail
				instagramUrl
				facebookUrl
				linkedInUrl
				youtubeUrl
				privacyPolicyContent
			}

			skillChecklistConfig {
				id
				skillChecklistUrl
			}
		}
	}
`

export const GET_COMPANY_PUBLIC_INFO_QUERY = gql`
	query getCompanyInfo {
		appInit {
			tenant {
				name
				timezone
			}

			chatIntegrationSetting {
				id
				provider
				configuration
			}

			tenantContacts {
				email
				phone
				contactType
			}

			tenantConfig {
				termUrl
				contactEmail
				instagramUrl
				facebookUrl
				linkedInUrl
				youtubeUrl
				privacyPolicyContent
			}
		}
	}
`

export const GET_SPECIALTY_MASTER_QUERY = gql`
	query SpecialtiesMaster {
		specialtiesMaster {
			id
			slug
		}
	}
`

export const GET_CURRENT_WORKER_INFO = gql`
	query {
		appInit {
			worker {
				id
				email
				firstName
				lastName
				maidenName
			}
		}
	}
`

export const GET_CONTACT_INFO_QUERY = gql`
	query getContactInfo {
		appInit {
			tenantContacts {
				email
				phone
				contactType
			}

			worker {
				employer {
					id
					firstName
					lastName
					phone
					email
					title
				}
				secondEmployer {
					id
					firstName
					lastName
					phone
					email
					title
				}
			}
		}
	}
`
