import axios from 'axios'
import { nanoid } from 'nanoid'
import { captureException } from '~/common/helpers'
import { action, computed, observable, persist, storage, store, toJS } from '~/common/mobx.decorator'
import { THEMES } from '~/themes'
import { notifyStore } from '~/stores/notify.store'
import { isEmpty } from 'lodash'
import { createMuiTheme } from '@material-ui/core'
import { DEFAULT_LOGO, LOGO_URLS } from '~/components/layout/image-urls'
import { THEME_CODE } from '~/constants/theme'

axios.interceptors.response.use(undefined, (error) => {
	captureException('Axios Client', error)
	return Promise.reject(error)
})

const LOGO_WIDTH_MAPPING = {
	[THEME_CODE.CROSSMED]: 163,
	[THEME_CODE.CONCENTRIC]: 173,
}

@store({ persist: true })
class AppStore {
	constructor() {
		// Set initial loading state
		this.isThemeLoading = true

		storage.ready(() => {
			this.ready = true
			if (!this._uniqueId) {
				this._uniqueId = nanoid()
			}
			// Load themeCode from localStorage first
			const savedThemeCode = localStorage.getItem('themeCode')

			if (savedThemeCode) {
				if (savedThemeCode === THEME_CODE.CROSSMED) {
					this.themeCode = savedThemeCode
					this.appTheme = THEMES.candidate
					this.isThemeLoading = false
				} else {
					const savedThemeConfig = localStorage.getItem('themeConfig')
					if (savedThemeConfig) {
						try {
							const parsedThemeConfig = JSON.parse(savedThemeConfig)
							if (parsedThemeConfig && typeof parsedThemeConfig === 'object') {
								const theme = createMuiTheme(parsedThemeConfig)
								this.themeCode = savedThemeCode
								this.appTheme = theme
								this.isThemeLoading = false
							} else {
								this.isThemeLoading = true
								console.warn('Invalid theme config format in localStorage')
							}
						} catch (error) {
							console.warn('Error parsing theme config from localStorage:', error)
						}
					}
				}
			}
			this.init()
		})
	}

	@persist @observable _uniqueId
	@observable config = null
	@observable ready = false
	@observable showError = false
	@observable retryInterval = null
	@observable isThemeLoading = true

	@observable meta_domain
	@observable store_android_url
	@observable store_ios_url
	@observable tenantId = ''
	@observable apiUrl = ''
	@persist('object') @observable appTheme = null
	@persist @observable themeCode = null
	@observable variablesConfig = {}

	@computed
	get id() {
		// Company Id
		return process.env.REACT_APP_COMPANY_ID
	}

	@computed
	get name() {
		return process.env.REACT_APP_COMPANY_NAME
	}

	@computed
	get logo() {
		return `/assets/logo.png`
	}

	@computed
	get getTheme() {
		if (this.isThemeLoading) {
			return undefined // MUI sẽ đợi cho đến khi có theme
		}

		// For Crossmed, always return default theme
		if (this.themeCode === THEME_CODE.CROSSMED) {
			return THEMES.candidate
		}

		// For other themes, must have their specific theme
		if (!this.appTheme) {
			return undefined // Return undefined to keep loading
		}

		return toJS(this.appTheme)
	}

	@computed
	get logoWidthMapping() {
		return LOGO_WIDTH_MAPPING[this.themeCode]
	}

	@computed
	get logoUrl() {
		return LOGO_URLS[this.themeCode] || DEFAULT_LOGO
	}

	@computed
	get isCrossmed() {
		return this.themeCode === THEME_CODE.CROSSMED
	}

	@computed
	get firebaseClientId() {
		return this.variablesConfig?.REACT_APP_FIREBASE_CLIENTID || process.env.REACT_APP_FIREBASE_CLIENTID
	}

	@computed
	get firebaseConfig() {
		return typeof this.variablesConfig?.REACT_APP_FIREBASE_CONFIG === 'string'
			? JSON.parse(this.variablesConfig?.REACT_APP_FIREBASE_CONFIG)
			: this.variablesConfig?.REACT_APP_FIREBASE_CONFIG
	}

	@computed
	get linkedInClientId() {
		return this.variablesConfig?.REACT_APP_LINKEDIN_CLIENT_ID || process.env.REACT_APP_LINKEDIN_CLIENT_ID
	}

	@computed
	get oneSignalAppId() {
		return this.variablesConfig?.REACT_APP_ONESIGNAL_APP_ID || process.env.REACT_APP_ONESIGNAL_APP_ID
	}

	@computed
	get trackingApiKey() {
		return this.variablesConfig?.REACT_APP_TRACKING_API_KEY || process.env.REACT_APP_TRACKING_API_KEY
	}

	@computed
	get trackingApiUrl() {
		return this.variablesConfig?.REACT_APP_TRACKING_API_URL || process.env.REACT_APP_TRACKING_API_URL
	}

	@computed
	get googlePlacesApiKey() {
		return this.variablesConfig?.REACT_APP_GOOGLE_PLACE_API_KEY || process.env.REACT_APP_GOOGLE_PLACE_API_KEY
	}

	@computed
	get branchIOConfig() {
		try {
			// Try to get from variablesConfig first
			if (this.variablesConfig?.REACT_APP_BRANCH_IO_CONFIG) {
				const parsedConfig =
					typeof this.variablesConfig.REACT_APP_BRANCH_IO_CONFIG === 'string'
						? JSON.parse(this.variablesConfig.REACT_APP_BRANCH_IO_CONFIG)
						: this.variablesConfig.REACT_APP_BRANCH_IO_CONFIG

				// Extract branchIO object from the config
				const config = parsedConfig.branchIO
				return config
			}

			// If no config found, return null and let the component handle fallback
			return null
		} catch (e) {
			return null
		}
	}

	@action
	init = async () => {
		try {
			await this.fetchTenantConfig()
			// Always fetch other configs
			await this.fetchFEConfig()
			await this.fetchAppConfig()
		} catch (error) {
			if (this.themeCode !== THEME_CODE.CROSSMED) {
				this.setShowError(true)
			}
			this.isThemeLoading = true
		}
	}

	fetchAppConfig = async () => {
		const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
		if (!isMobile) {
			await notifyStore.initOneSignal().then(() => notifyStore.sendInfo())
		}
	}

	fetchFEConfig = async () => {
		const response = await fetch('/app-config.json')
		const data = await response.json()

		return data
	}

	@action
	loadThemeFromStorage = async () => {
		try {
			// First try to load from mobx-persist storage
			if (this.themeCode && this.appTheme) {
				return true
			}

			// If not in mobx-persist, try localStorage
			const savedThemeCode = localStorage.getItem('themeCode')
			const savedThemeConfig = localStorage.getItem('themeConfig')

			if (savedThemeCode && savedThemeConfig) {
				const parsedThemeConfig = JSON.parse(savedThemeConfig)
				// Validate theme config
				if (!parsedThemeConfig || typeof parsedThemeConfig !== 'object') {
					console.warn('Invalid theme config format in localStorage')
					return false
				}
				const theme = createMuiTheme(parsedThemeConfig)
				// Set theme only if successfully created
				this.themeCode = savedThemeCode
				this.appTheme = theme
				return true
			}

			return false
		} catch (error) {
			console.warn('Error loading theme from localStorage:', error)
			return false
		}
	}

	@action
	saveThemeToStorage = (themeCode, themeConfig) => {
		// Skip storage saving for Crossmed theme
		if (themeCode === THEME_CODE.CROSSMED) {
			return
		}

		try {
			if (!themeCode || !themeConfig || typeof themeConfig !== 'object') {
				return
			}

			// Create theme first to validate
			const theme = createMuiTheme(themeConfig)

			// Only save if theme creation successful
			localStorage.setItem('themeCode', themeCode)
			localStorage.setItem('themeConfig', JSON.stringify(themeConfig))
			this.themeCode = themeCode
			this.appTheme = theme
			this.isThemeLoading = false
		} catch (error) {
			// Clear any partial saves
			localStorage.removeItem('themeCode')
			localStorage.removeItem('themeConfig')
			throw error
		}
	}

	@action
	fetchTenantConfig = async () => {
		const isDevelopment = window.location.hostname === 'localhost'

		try {
			const headerConfig = isDevelopment
				? {
						headers: {
							'tenant-id': process.env.REACT_APP_TENANT_ID,
							Accept: 'application/json',
						},
				  }
				: {
						headers: {
							Accept: 'application/json',
						},
				  }

			const response = await axios.get(`${process.env.REACT_APP_PROXY_API_URL}/api/public/v1/connections`, headerConfig)

			if (response?.data) {
				this.setTenantId(response.data?.tenantId)
				this.setApiUrl(`https://${response.data?.apiDomain}`)
			} else {
				this.setApiUrl(process.env.REACT_APP_DEFAULT_PROXY_API_URL)
				throw new Error('No data returned')
			}

			// Set theme code and save to storage
			if (response?.data?.themeConfig?.themeCode) {
				const themeCode = response?.data?.themeConfig?.themeCode
				// Only update themeCode if it's different from localStorage
				const savedThemeCode = localStorage.getItem('themeCode')
				if (themeCode !== savedThemeCode) {
					this.setThemeCode(themeCode)
					localStorage.setItem('themeCode', themeCode)
				}

				if (themeCode === THEME_CODE.CROSSMED) {
					this.appTheme = THEMES.candidate
					this.isThemeLoading = false
				} else {
					// For other themes, try to load from webTheme config
					if (response?.data?.themeConfig?.webTheme && !isEmpty(response?.data?.themeConfig?.webTheme)) {
						try {
							const apiThemeConfig =
								typeof response?.data?.themeConfig?.webTheme === 'string'
									? JSON.parse(response?.data?.themeConfig?.webTheme)
									: response?.data?.themeConfig?.webTheme

							if (!apiThemeConfig || typeof apiThemeConfig !== 'object') {
								throw new Error('Invalid theme config from API')
							}

							await this.saveThemeToStorage(themeCode, apiThemeConfig)
						} catch (error) {
							this.isThemeLoading = true
							// Try to load from storage as fallback
							const loadedFromStorage = await this.loadThemeFromStorage()
							if (!loadedFromStorage) {
								// If both API and storage fail, show error and keep retrying
								this.setShowError(true)
							}
						}
					} else {
						// If no webTheme config, show error and keep retrying
						this.setShowError(true)
					}
				}
			} else {
				this.setThemeCode(null)
				// If no theme code from API, show error and keep retrying
				this.setShowError(true)
			}

			// Always set variables config regardless of theme
			if (response?.data?.themeConfig?.config) {
				this.setVariablesConfig(response?.data?.themeConfig?.config)
			}

			this.setShowError(false)
		} catch (e) {
			console.error('Error fetching tenant config:', e)

			// For non-Crossmed themes, try to load from storage
			if (this.themeCode !== THEME_CODE.CROSSMED) {
				const loadedFromStorage = await this.loadThemeFromStorage()
				if (!loadedFromStorage) {
					// If storage fails too, show error and keep retrying
					this.setShowError(true)
				}
			}
		}
	}

	@action
	setTenantId = (tenantId) => {
		this.tenantId = tenantId
		sessionStorage.setItem('tenantId', tenantId)
	}

	@action
	setApiUrl = (apiUrl) => {
		this.apiUrl = apiUrl
		sessionStorage.setItem('apiUrl', apiUrl)
		axios.defaults.baseURL = `${apiUrl}/api/public/v1`
	}

	@action
	setThemeCode = (themeCode) => {
		this.themeCode = themeCode
	}

	@action
	setVariablesConfig = (variablesConfig) => {
		this.variablesConfig = variablesConfig
		this.loadGoogleMapsScript()
	}

	loadGoogleMapsScript = () => {
		const script = document.createElement('script')

		script.addEventListener('load', () => {
			if (!window.google?.maps) {
				return
			}
		})

		script.addEventListener('error', () => {
			return
		})

		script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googlePlacesApiKey}&libraries=places&v=weekly&loading=async`
		script.defer = true
		document.head.appendChild(script)
	}

	async getFallbackBranchIOConfig() {
		try {
			const response = await fetch('/app-config.json')
			const data = await response.json()
			return data.branchIO
		} catch (e) {
			return null
		}
	}

	@action
	setShowError = (value) => {
		this.showError = value
		if (value && !this.retryInterval) {
			// Only start retry if not already retrying
			this.startRetryInterval()
		} else if (!value) {
			// Clear retry interval when hiding error
			this.clearRetryInterval()
		}
	}

	@action
	startRetryInterval = () => {
		// Clear any existing interval
		this.clearRetryInterval()

		// Set new interval to retry every 5 seconds
		this.retryInterval = setInterval(async () => {
			try {
				await this.fetchTenantConfig()
			} catch (error) {
				console.error('Retry attempt failed:', error)
				// Don't clear interval on error, keep retrying
			}
		}, 5000) // 5 seconds
	}

	@action
	clearRetryInterval = () => {
		if (this.retryInterval) {
			clearInterval(this.retryInterval)
			this.retryInterval = null
		}
	}

	@action
	stopRetry = () => {
		this.clearRetryInterval()
	}
}

export const appStore = new AppStore()
window.appStore = appStore
